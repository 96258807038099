import { React, useState } from "react";
import { useParams } from "react-router-dom";
import productitem from "./ProductsData";
import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import CheckIcon from "@mui/icons-material/Check";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../../styles.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CircleIcon from "@mui/icons-material/Circle";
const ProductDetail = () => {
  const { productId } = useParams();
  const thisProductt = productitem.find(
    (prod) => prod.categoryId === productId
  );
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <div className="bgdetail PersianFont">
        <Header />
        <div className="container mx-auto w-full text-center mt-12 md:mt-20">
          <div className="w-full md:w-2/5 p-5 md:p-0 inline-block">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {thisProductt.image.map((image, index) => (
                <SwiperSlide key={index}>
                  <img className="w-10/12 rounded-2xl" src={image} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper mt-4"
            >
              {thisProductt.image.map((image, index) => (
                <SwiperSlide key={index}>
                  <img className="w-1/2 rounded-2xl" src={image} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="text-right float-right p-5 w-full md:w-1/2 ml-1 md:ml-14">
            <div className="inline-block rtl">
              <p className="text-[#D9D9D9] inline-block text-sm font-light ml-2">
                محصولات
              </p>
              <CircleIcon color="primary" sx={{ fontSize: 8 }} />
              <p className="text-[#D9D9D9] inline-block text-sm font-light mr-2">
                {thisProductt.title}
              </p>
            </div>
            <div className="w-full block mt-5">
              <p className="text-white text-3xl text-right">
                {thisProductt.titleb}
              </p>
              <p className="text-green-400 bg-green-800 text-center w-fit rounded-3xl my-6 py-1 px-3 text-base inline-grid">
                {thisProductt.title}
              </p>
            </div>
            <div className="w-full block">
              <p className="text-neutral-200 text-base block rtl">
                {thisProductt.desc}
              </p>
            </div>
            <div className="inline-block w-full mt-5">
              <button className="border border-green-400 rounded-3xl text-white w-2/5 md:w-1/3 text-sm md:text-base my-6 py-2 px-4">
                ثبت سفارش
              </button>
              <button className="text-white w-1/2 md:w-3/5 text-sm md:text-base ml-6">
                مشاهده راه های دیگر خرید
              </button>
            </div>
          </div>

          <div className="border-t border-blue-600 mt-10 mb-10 inline-block">
            <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 p-0 mx-1 mt-10 text-right min-h-40">
              <div className="s1 text-white rounded-3xl mx-1 text-right table w-full">
                <div className="table-row-group">
                  <div className="blog table-cell">
                    <p className="text-white w-full mb-4 text-xl">کیفیت برتر</p>
                    <p className="text-[#c0bfba] font-normal md:text-base text-sm mb-4">
                      مطابقت با بالاترین استانداردها جهت رفع نیازهای صنعتی{" "}
                    </p>
                  </div>
                  <div className="table-cell px-6">
                    <CheckIcon className="text-[#00D777]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 p-0 mx-1 mt-10 text-right min-h-40">
              <div className="s1 text-white rounded-3xl mx-1 text-right table w-full">
                <div className="table-row-group">
                  <div className="blog table-cell">
                    <p className="text-white w-full mb-4 text-xl">
                      کاربرد چندگانه
                    </p>
                    <p className="text-[#c0bfba] font-normal md:text-base text-sm mb-4">
                      کاربرد در صنایع مختلف جهت استفاده در کارخانه های تولیدی،
                      ازمایشگاه ها و پروژه های تحقیقاتی
                    </p>
                  </div>
                  <div className="table-cell px-6">
                    <CheckIcon className="text-[#00D777]" />
                  </div>
                </div>
              </div>
            </div>
            <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 p-0 mx-1 mt-10 text-right min-h-40">
              <div className="s1 text-white rounded-3xl mx-1 text-right table w-full">
                <div className="table-row-group">
                  <div className="blog table-cell">
                    <p className="text-white w-full mb-4 text-xl">
                      عملکرد قابل اعتماد
                    </p>
                    <p className="text-[#c0bfba] font-normal md:text-base text-sm mb-4">
                      با ارائه محصولات با کیفیت بالا، اطمینان حاصل شده است که
                      شما می‌توانید به سرعت و با اعتماد نتایج دقیق و قابل تکرار
                      را در آزمایشگاه خود به دست آورید.
                    </p>
                  </div>
                  <div className="table-cell px-6">
                    <CheckIcon className="text-[#00D777]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProductDetail;
