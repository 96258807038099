const AboutBox = () => {
  return (
    <>
      <div className="p-5 container w-full mx-auto">
        <div className="bg-[#00D777] w-full md:w-1/4 rounded-3xl text-left p-5 mr-4 inline-block">
          <p className="text-4xl text-white font-semibold mb-8 md:mb-10 lg:mb-20 sm:text-3xl">
            70%
          </p>
          <p className="text-xl text-white font-medium mb-2">
            High satisfaction rates
          </p>
          <p className="text-white text-sm font-normal">
            Positive feedback is a testament to our commitment to quality and
            service.
          </p>
        </div>
        <div className="bg-[#D9D8D6] w-full md:w-1/4 rounded-3xl text-left p-5 mr-4 mt-5 inline-block">
          <p className="text-4xl text-black font-semibold mb-8 md:mb-3 text-4xll lg:mb-4">
            +4
          </p>
          <p className="text-xl text-black font-medium mb-2">
            Type of Products
          </p>
          <p className="text-black text-sm font-normal">
            A wide selection of acids and fertilizers that meet the needs of
            different industries.
          </p>
        </div>
        <div className="bg-[#2159F2] w-full md:w-1/4 rounded-3xl text-left p-5 mr-4 mt-5 inline-block">
          <p className="text-4xl text-white font-semibold mb-12 md:mb-10 lg:mb-20">
            +16
          </p>
          <p className="text-xl text-white font-medium mb-2">
            Years of Experience
          </p>
          <p className="text-white text-sm font-normal">
            More than 16 years of experience in the production of agricultural
            fertilizers and chemical acids.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutBox;
