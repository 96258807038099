// import Header from "../../header/header";
import Header from "../../header/Navbar";
import AboutSlogun from "../../aboutSlogun/AboutSlogun";
import Footer from "../../Newfooter/newfooter";
import AboutBox from "../../about/aboutBox.js";
import Services from "../../services/services";

const About = () => {
  return (
    <>
      <div className="bg-black text-center EnglishFont">
        <Header />
        <AboutSlogun />
        <AboutBox />
        <p className="text-white text-base lg:text-xl mb-7 my-10 mx-auto w-10/12 md:w-1/2 ">
          Kimiadaran Kavir began its operation in 2008, with the goal of
          manufacturing fundamental agricultural products in order to improve
          the standards and supply the demand that the industry needs. Our
          products include Granular Triple superphosphate, Phosphoric Acid,
          Sulphuric Acid, and Sodium Silica Fluoride. For the firm's sustainable
          development and fulfillment of the supply chain, Kimia Daran Kavir
          SHIN has started its operations in the sales and distribution of
          liquid and powder fertilizers under the brand name LANDCO.
        </p>
        <Services />
        <Footer />
      </div>
    </>
  );
};
export default About;
