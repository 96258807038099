// import Category from "./category/category";
import { Link } from "react-router-dom";
import categoryData from "./category/categoryData";
// let data = [
//   { id: 1, icon: "./Image/TestTube.png", title: "Chemicals & Acids" },
//   { id: 2, icon: "./Image/NaturalFood.png", title: "Fertilizer" },
//   { id: 3, icon: "./Image/CowBreed.png", title: "LiveStock & Poultry" },
// ];
// const Categories = ({ data }) => {
const Categories = () => {
  return (
    <>
      <div className="inline-grid mt-32 lg:mt-36 mb-10 container w-full mx-auto text-center">
        <div className="justify-self-center">
          {categoryData?.map((category) => {
            return (
              <div
                className="px-6 inline-block w-full lg:w-1/3 mb-4 lg:mb-0 align-bottom"
                key={category.id}
              >
                <div className="border border-[#00008b] rounded-3xl text-left p-5 grid min-h-[360px] ">
                  <img src={category.icon}></img>
                  <p className="text-white text-3xl md:text-xl my-3">
                    {category.title}
                  </p>
                  <p className="text-base text-[#C0BFBA] leading-5 mb-6 lg:mb-14">
                    {category.desc}
                  </p>
                  <label className="text-[#008000] mb-2">{category.num} </label>
                  <br />
                  <Link to="/products">
                    <button className="border border-[#2159F2] rounded-3xl py-2 px-2 text-white mt-3">
                      see all products
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Categories;
