import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import Blogdata from "../../Blog/Blogdata";
import EastIcon from "@mui/icons-material/East";
import { useParams, Link } from "react-router-dom";
const Blogdetail = () => {
  const { blogId } = useParams();
  const thisBlog = Blogdata.find((blog) => blog.id === blogId);
  return (
    <>
      <div className="bgpost PersianFont">
        <Header />
        <div className="w-1/2 mx-auto">
          <div className="block pb-10 border-b border-[#807E75]">
            <Link to="/Fa/blog">
              <EastIcon className="text-[#2159f2] inline-block ml-2" />
            </Link>
            <p className="text-[#c0bfba] text-xl font-normal text-left mt-4 mb-10 inline-block">
              {thisBlog.datefa}
            </p>
            <p className="text-white text-4xl font-medium mb-7">
              {thisBlog.titlefa}
            </p>
            <p className="text-[#C0BFBA] text-xl">{thisBlog.shortdescfa}</p>
          </div>
          <div className="block">
            <p className="text-[#C0BFBA] text-lg mt-7">{thisBlog.descfa}</p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blogdetail;
