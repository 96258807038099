let State = [
  {
    id: "1",
    title: "Not started yet",
    titlefa: "آغاز پروژه",
  },
  {
    id: "2",
    title: "Under Construction",
    titlefa: "در حال توسعه",
  },
  {
    id: "3",
    title: "Ready to Operate",
    titlefa: "آماده اجرا",
  },
  {
    id: "4",
    title: "Finished",
    titlefa: "به پایان رسیده",
  },
];
export default State;
