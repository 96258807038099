import { Typography } from "@material-tailwind/react";

const LINKS = [
  {
    title: "محصولات",
    items: ["کود", "مواد شیمیایی", "خوراک دام و طیور"],
  },
  {
    title: "سازمان",
    items: ["درباره ما", "پروژه ها", "همکاری با ما", "مزایده"],
  },
  {
    title: "منابع",
    items: ["آزمایشگاه", "اخبار", "حفظ حریم خصوصی"],
  },
  {
    title: "ارتباط با ما",
    items: [
      "info@kimiadaran.com",
      "+98(21)71193",
      "ایران، تهران، میرداماد، خیابان رازان شمالی، خیابان دهم، پلاک 5",
    ],
  },
];

const currentYear = new Date().getFullYear();

export default function FooterWithSocialLinks() {
  return (
    <footer className="relative w-full pt-10">
      <img
        src={require("../../../../Images/landco.png")}
        alt="landco"
        className="w-16 mx-auto mb-8 mt-10"
      ></img>
      <div className="mx-auto w-full max-w-7xl px-8 py-4 border-t">
        <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-2">
          {/* <Typography variant="h5" className="mb-6">
            Material Tailwind
          </Typography> */}
          <img
            src={require("../../../../Images/kdk.png")}
            alt="KimiaDaran"
          ></img>
          <div className="grid grid-cols-5 md:grid-cols-4 justify-between gap-4 text-right">
            {LINKS.map(({ title, items }) => (
              <ul key={title}>
                <Typography
                  variant="small"
                  color="white"
                  className="mb-3 font-semibold opacity-60"
                >
                  {title}
                </Typography>
                {items.map((link) => (
                  <li key={link}>
                    <Typography
                      as="a"
                      href="#"
                      color="white"
                      className="py-1.5 text-xs md:text-sm font-normal transition-colors hover:text-white"
                    >
                      {link}
                    </Typography>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="mt-12 flex w-full flex-col items-center justify-center border-white py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-white md:mb-0"
          >
            &copy; {currentYear} <a href="https://www.kimiadaran.com/">KDK</a>
            .کلیه حقوق محفوظ است
          </Typography>
          <div className="flex gap-4 text-white sm:justify-center">
            <Typography
              as="a"
              href="https://www.instagram.com/kimiadarankavir?igsh=MXQ1NGt6NWp6Mm9vYg%3D%3D&utm_source=qr"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </Typography>
            <Typography
              as="a"
              href="https://wa.me/+989331770557"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.07 2a10.16 10.16 0 00-7.19 2.97A10.12 10.12 0 002 12.17a10.35 10.35 0 001.4 5.2L2 22l4.73-1.25a10.16 10.16 0 004.94 1.29h.01a10.12 10.12 0 007.2-17.3A10.16 10.16 0 0012.07 2zm0 17.91a8.19 8.19 0 01-4.4-1.28l-.32-.19-2.8.74.74-2.73-.21-.33a8.18 8.18 0 0112.89-9.78 8.13 8.13 0 010 11.53 8.15 8.15 0 01-5.9 2.46zm4.51-6.01c-.25-.12-1.48-.73-1.71-.82-.23-.08-.4-.12-.57.12-.17.25-.66.82-.81.99-.15.17-.3.2-.56.07-.25-.12-1.05-.39-2-1.24-.74-.66-1.24-1.47-1.39-1.72-.14-.25-.01-.38.11-.5.11-.11.25-.3.37-.45.12-.15.16-.25.24-.41.08-.16.04-.3-.02-.42-.06-.12-.57-1.37-.79-1.87-.21-.5-.42-.43-.57-.44h-.49c-.17 0-.42.06-.65.3-.22.24-.85.83-.85 2.02s.87 2.35.99 2.52c.12.17 1.72 2.63 4.18 3.69.58.25 1.03.4 1.38.51.58.18 1.11.15 1.53.09.47-.07 1.48-.6 1.69-1.18.21-.57.21-1.06.15-1.18-.06-.11-.23-.17-.49-.29z"
                  clipRule="evenodd"
                />
              </svg>
            </Typography>
            <Typography
              as="a"
              href="https://www.linkedin.com/company/kimia-daran-kavir/"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M4.98 3.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm.02 3.05a1.55 1.55 0 100-3.1 1.55 1.55 0 000 3.1zM2.5 8.41h5v12.09h-5V8.41zM4.32 9.09c-.66 0-1.19.53-1.19 1.19v8.13c0 .66.53 1.19 1.19 1.19h.19c.66 0 1.19-.53 1.19-1.19V10.28c0-.66-.53-1.19-1.19-1.19h-.19zM8.41 8.41h4.63v1.66c.61-.98 1.72-1.8 3.03-1.8 3.23 0 3.82 2.12 3.82 4.89v7.34h-5v-6.64c0-1.56-.03-3.56-2.17-3.56-2.17 0-2.51 1.7-2.51 3.45v6.75h-5V8.41z"
                  clipRule="evenodd"
                />
              </svg>
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}
