import { Link } from "react-router-dom";
import categoryData from "./CategoryData";
const Categories = () => {
  return (
    <>
      <div className="inline-grid mt-24 md:mt-30 lg:mt-36 mb-10 container w-full mx-auto text-center">
        <div className="justify-self-center">
          {categoryData?.map((category) => {
            return (
              <div
                className="px-6 inline-block w-full lg:w-1/3 mb-4 lg:mb-0 align-bottom"
                key={category.id}
              >
                <div className="border border-[#00008b] rounded-3xl text-right p-5 grid min-h-[360px] ">
                  <img
                    src={category.icon}
                    className="inline-grid justify-self-start"
                  ></img>
                  <p className="text-white text-xl lg:text-2xl my-3">
                    {category.title}
                  </p>
                  <p className="text-base text-[#C0BFBA] leading-5 mb-6 lg:mb-14 rtl PersianMed">
                    {category.desc}
                  </p>
                  <label className="text-[#008000] mb-2 rtl">
                    {category.num}
                  </label>
                  <br />
                  <Link to="/Fa/products">
                    <button className="border border-[#2159F2] rounded-3xl py-2 px-2 text-white mt-3">
                      مشاهده همه محصولات
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Categories;
