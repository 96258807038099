const AboutSlogun = () => {
  return (
    <>
      <div className="md:w-1/2 w-11/12 mx-auto my-10">
        <p className="text-white text-3xl lg:text-5xl font-semibold mb-10">
          We are making a new world!
        </p>
        <p className="text-white text-base lg:text-xl mb-10">
          We seek to provide quality products with international standards in
          all value-creation chains. New solution providing
        </p>
        <img
          src={require("../../Images/aboutpage.JPG")}
          className="mx-auto my-4 rounded-2xl"
        ></img>
        <p className="text-white text-base lg:text-xl my-10">
          Our approach is to innovate in the industry, create an effective
          supply chain, and maintain excellence in product distribution.
        </p>
      </div>
    </>
  );
};
export default AboutSlogun;
