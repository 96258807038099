// import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages";
import About from "../Pages/about/About";
import Contact from "../Pages/contact/Contact";
import Products from "../Pages/products/Products";
import ProductDetail from "../Products/productDetail";
import InAction from "../Pages/InAction/InAction";
import Fa from "../Pages/Fa/Fabrowser";
import InactionDetail from "../Pages/InAction/InActionDetail";
import Auction from "../Pages/Auction/Auction";
import Job from "../Pages/Job/job";
import Form from "../Pages/Job/jobForm";
import NotFound from "../Pages/NotFound/NotFound";
import Blog from "../Pages/Blog/Blog";
import BlogDetail from "../Pages/Blog/Blogdetail";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:productId" element={<ProductDetail />} />
          <Route path="/inaction" element={<InAction />} />
          <Route path="/inaction/:inactionId" element={<InactionDetail />} />
          <Route path="/auction" element={<Auction />} />
          <Route path="/job" element={<Job />} />
          <Route path="/form" element={<Form />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogId" element={<BlogDetail />} />
          <Route path="/Fa/*" element={<Fa />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      {/* <Router basename="Fa">
        <Routes>
          <Route path="/" element={<HomeFa />} />
          <Route path="/Contact" element={<ContactFa />} />
          <Route path="/About" element={<AboutFa />} />
          <Route path="/Products" element={<ProductsFa />} />
          <Route path="/Products/:productId" element={<ProductDetailFa />} />
        </Routes>
      </Router> */}
    </>
  );
}

export default App;
