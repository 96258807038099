import InActionData from "./InActionData";
import React from "react";
import { Link, useParams } from "react-router-dom";
// import Header from "../../header/header";
import Header from "../../header/Navbar";
import Footer from "../../Newfooter/newfooter";
import inDetailImg from "../../../Images/Rectangle.png";
import State from "./StateData";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const InActionDetail = () => {
  const { inactionId } = useParams();
  const thisAction = InActionData.find((prod) => prod.id === inactionId);
  return (
    <>
      <div className="bgdetail EnglishFont">
        <Header />
        <div className="px-5 container mx-auto text-center">
          <div
            className="p-5 w-full 2xl:w-10/12 mx-auto my-5"
            key={thisAction.id}
          >
            <div className="text-left inline-block md:w-1/2 w-full">
              <Link to="/inaction">
                <KeyboardBackspaceIcon className="inline-block text-[#2159f2]" />
              </Link>
              <p className="text-gray-500 text-base inline-block">
                {thisAction.date}
              </p>
              <h3 className="text-white md:text-4xl text-lg font-medium mb-4">
                {thisAction.title}
              </h3>
              <p className="text-white md:text-lg text-md font-medium">
                {thisAction.desc}
              </p>
            </div>
            <div className="pic w-full md:w-1/2 inline-block">
              <img src={thisAction.image}></img>
            </div>
          </div>
          <div className="p-5 w-full 2xl:w-10/12 mx-auto my-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {State?.map((state) => {
                return state.id === thisAction.StateId ? (
                  <div>
                    <div className="text-green-600 text-xs w-48 text-left mb-2">
                      Not started yet
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
                      <div className="bg-green-600 h-1.5 rounded-full dark:bg-blue-500"></div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="text-gray-600 text-xs w-48 text-left mb-2">
                      Under Construction
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
                      <div className="bg-gray-600 h-1.5 rounded-full"></div>
                    </div>
                  </div>
                );
              })}
            </div>
            <p className="text-white text-xl md:text-3xl leading-10 my-10 md:my-20">
              Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Curabitur tempus urna at turpis condimentum
              lobortis.
            </p>

            <img className="" src={inDetailImg}></img>
            <div className="grid grid-cols- md:grid-cols-3 gap-4 my-9">
              <div className="p-5">
                <p className="text-white text-lg text-left">
                  Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus
                  ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel
                  bibendum lorem. Morbi convallis convallis diam sit amet
                  lacinia. Aliquam in elementum tellus.
                </p>
              </div>
              <div className="p-5">
                <p className="text-white text-lg text-left">
                  Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor purus luctus
                  enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus
                  ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel
                  bibendum lorem. Morbi convallis convallis diam sit amet
                  lacinia. Aliquam in elementum tellus.
                </p>
              </div>
              <div className="p-5">
                <p className="text-white text-left">
                  Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Curabitur tempus urna at
                  turpis condimentum lobortis. Ut commodo efficitur neque.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default InActionDetail;
