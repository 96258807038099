// import Header from "../Header/Header";
import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import jobData from "../../Job/jobData";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles.css";
import { Link, useNavigate } from "react-router-dom";

const Job = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const showAlert = () => {
    const job = jobData[0];
    MySwal.fire({
      title: job.titlefa,
      html: (
        <div className="text-right rtl">
          <div className="inline-block float-right text-right w-1/3">
            <svg
              className="inline-block text-right mr-2"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_368_2115"
                // style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_368_2115)">
                <path
                  d="M1.66602 17.5V2.5H9.99935V5.83333H18.3327V17.5H1.66602ZM3.33268 15.8333H8.33268V14.1667H3.33268V15.8333ZM3.33268 12.5H8.33268V10.8333H3.33268V12.5ZM3.33268 9.16667H8.33268V7.5H3.33268V9.16667ZM3.33268 5.83333H8.33268V4.16667H3.33268V5.83333ZM9.99935 15.8333H16.666V7.5H9.99935V15.8333ZM11.666 10.8333V9.16667H14.9993V10.8333H11.666ZM11.666 14.1667V12.5H14.9993V14.1667H11.666Z"
                  fill="#D9D8D6"
                />
              </g>
            </svg>
            <p className="inline-block text-base text-[#D9D8D6] align-middle">
              {job.position}
            </p>
          </div>
          <div className="inilne-block text-left w-1/2 mb-4">
            <svg
              className="inline-block mr-2"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_368_2130"
                //   style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_368_2130)">
                <path
                  d="M10.0007 10C10.459 10 10.8513 9.83681 11.1777 9.51042C11.5041 9.18403 11.6673 8.79167 11.6673 8.33333C11.6673 7.875 11.5041 7.48264 11.1777 7.15625C10.8513 6.82986 10.459 6.66667 10.0007 6.66667C9.54232 6.66667 9.14996 6.82986 8.82357 7.15625C8.49718 7.48264 8.33398 7.875 8.33398 8.33333C8.33398 8.79167 8.49718 9.18403 8.82357 9.51042C9.14996 9.83681 9.54232 10 10.0007 10ZM10.0007 16.125C11.6951 14.5694 12.952 13.1562 13.7715 11.8854C14.5909 10.6146 15.0007 9.48611 15.0007 8.5C15.0007 6.98611 14.518 5.74653 13.5527 4.78125C12.5875 3.81597 11.4034 3.33333 10.0007 3.33333C8.59787 3.33333 7.41385 3.81597 6.44857 4.78125C5.48329 5.74653 5.00065 6.98611 5.00065 8.5C5.00065 9.48611 5.41037 10.6146 6.22982 11.8854C7.04926 13.1562 8.30621 14.5694 10.0007 16.125ZM10.0007 18.3333C7.76454 16.4306 6.0944 14.6632 4.99023 13.0312C3.88607 11.3993 3.33398 9.88889 3.33398 8.5C3.33398 6.41667 4.00412 4.75694 5.3444 3.52083C6.68468 2.28472 8.23676 1.66667 10.0007 1.66667C11.7645 1.66667 13.3166 2.28472 14.6569 3.52083C15.9972 4.75694 16.6673 6.41667 16.6673 8.5C16.6673 9.88889 16.1152 11.3993 15.0111 13.0312C13.9069 14.6632 12.2368 16.4306 10.0007 18.3333Z"
                  fill="#D9D8D6"
                />
              </g>
            </svg>
            <p className="inline-block text-base text-[#D9D8D6] align-middle">
              {job.locationfa}
            </p>
          </div>
          <hr className="text-neutral-500 w-full block h-[0.5px]" />
          <p className="text-base text-white my-4 font-semibold">شرایط</p>
          <ul className="block text-base text-right text-[#D9D8D6]">
            {job.conditionfa.map((condfa, index) => (
              <li className="list-disc list-inside ml-3" key={index}>
                {condfa}
              </li>
            ))}
          </ul>
          <p className="text-base text-white my-4 font-semibold">
            شاخص های کلیدی از نظر کارفرما{" "}
          </p>
          <ul className="block text-base text-right text-[#D9D8D6]">
            {job.indicatorfa.map((indfa, index) => (
              <li className="list-disc list-inside ml-3" key={index}>
                {indfa}
              </li>
            ))}
          </ul>
          <p className="text-base text-white my-4 font-semibold">
            شرح شغل و وظایف{" "}
          </p>
          <ul className="block text-base text-right text-[#D9D8D6]">
            {job.descfa.map((descfa, index) => (
              <li className="list-disc list-inside ml-3" key={index}>
                {descfa}
              </li>
            ))}
          </ul>
        </div>
      ),

      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: "ارسال رزومه",
      customClass: {
        popup: "swal2-popup",
        title: "swal2-titlefa",
        htmlContainer: "swal2-html-containerfa",
        confirmButton: "confirmButton",
      },
      width: "42%",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/Fa/form");
      }
    });
  };
  return (
    <>
      <div className="text-center rtl bgjob PersianFont">
        <Header />
        <div className="container mx-auto my-14 md:my-20 text-center">
          <p className="text-white font-bold mb-10 text-4xl">همکاری با ما</p>
          <p className="text-lg w-4/5 text-white rtl font-medium lg:w-1/2 mx-auto py-3">
            با ما رشد کنید! عضوی از تیم ما شوید.
          </p>
        </div>
        <div className="mx-auto justify-center flex w-11/12 md:w-7/12 gap-9 text-white">
          {jobData?.map((elem) => {
            return (
              <div
                className="lg:basis-3/5 2xl:basis-2/5 basis-full border border-neutral-700 rounded-3xl p-9"
                key={elem.id}
              >
                <p className="block text-right text-xl font-medium md:text-4xl mb-4">
                  {elem.titlefa}
                </p>
                <div className="inline-block w-2/3 text-center md:float-left md:text-right md:w-1/2">
                  <svg
                    className="inline-block text-right mr-0 md:mr-2"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_368_2115"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_368_2115)">
                      <path
                        d="M1.66602 17.5V2.5H9.99935V5.83333H18.3327V17.5H1.66602ZM3.33268 15.8333H8.33268V14.1667H3.33268V15.8333ZM3.33268 12.5H8.33268V10.8333H3.33268V12.5ZM3.33268 9.16667H8.33268V7.5H3.33268V9.16667ZM3.33268 5.83333H8.33268V4.16667H3.33268V5.83333ZM9.99935 15.8333H16.666V7.5H9.99935V15.8333ZM11.666 10.8333V9.16667H14.9993V10.8333H11.666ZM11.666 14.1667V12.5H14.9993V14.1667H11.666Z"
                        fill="#D9D8D6"
                      />
                    </g>
                  </svg>
                  <p className="inline-block text-base text-neutral-100 align-middle">
                    {elem.position}
                  </p>
                </div>
                <div className="inilne-block float-right text-right w-1/3 mb-4">
                  <svg
                    className="inline-block mr-2"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_368_2130"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_368_2130)">
                      <path
                        d="M10.0007 10C10.459 10 10.8513 9.83681 11.1777 9.51042C11.5041 9.18403 11.6673 8.79167 11.6673 8.33333C11.6673 7.875 11.5041 7.48264 11.1777 7.15625C10.8513 6.82986 10.459 6.66667 10.0007 6.66667C9.54232 6.66667 9.14996 6.82986 8.82357 7.15625C8.49718 7.48264 8.33398 7.875 8.33398 8.33333C8.33398 8.79167 8.49718 9.18403 8.82357 9.51042C9.14996 9.83681 9.54232 10 10.0007 10ZM10.0007 16.125C11.6951 14.5694 12.952 13.1562 13.7715 11.8854C14.5909 10.6146 15.0007 9.48611 15.0007 8.5C15.0007 6.98611 14.518 5.74653 13.5527 4.78125C12.5875 3.81597 11.4034 3.33333 10.0007 3.33333C8.59787 3.33333 7.41385 3.81597 6.44857 4.78125C5.48329 5.74653 5.00065 6.98611 5.00065 8.5C5.00065 9.48611 5.41037 10.6146 6.22982 11.8854C7.04926 13.1562 8.30621 14.5694 10.0007 16.125ZM10.0007 18.3333C7.76454 16.4306 6.0944 14.6632 4.99023 13.0312C3.88607 11.3993 3.33398 9.88889 3.33398 8.5C3.33398 6.41667 4.00412 4.75694 5.3444 3.52083C6.68468 2.28472 8.23676 1.66667 10.0007 1.66667C11.7645 1.66667 13.3166 2.28472 14.6569 3.52083C15.9972 4.75694 16.6673 6.41667 16.6673 8.5C16.6673 9.88889 16.1152 11.3993 15.0111 13.0312C13.9069 14.6632 12.2368 16.4306 10.0007 18.3333Z"
                        fill="#D9D8D6"
                      />
                    </g>
                  </svg>
                  <p className="inline-block text-base text-neutral-100 align-middle">
                    {elem.locationfa}
                  </p>
                </div>
                <hr className="text-neutral-500 w-full block" />
                <p className="text-white text-base text-right block font-medium my-4">
                  شرایط
                </p>
                <ul className="block text-base text-right text-[#D9D8D6]">
                  {elem.conditionfa?.map((item) => {
                    return (
                      <li className="list-disc list-inside ml-3" key={item}>
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <button
                  onClick={() => showAlert()}
                  className="text-white text-center text-base py-3 px-8 border border-green-400 rounded-3xl float-left mt-10 hover:bg-green-400"
                >
                  بیشتر بدانید
                </button>
              </div>
            );
          })}
        </div>
        <div className=" rtl text-center block text-white mx-auto lg:w-[45%] lg:my-28 my-16 w-10/12">
          <p className="text-3xl md:text-4xl font-bold mb-2">
            موقعیت شغلی مناسب خود را پیدا نکردید؟
          </p>
          <p className="text-3xl md:text-4xl font-bold">
            ما از برقراری ارتباط جدید استقبال ‌می‌کنیم.
          </p>
          <p className="text-lg text-neutral-200 my-8">
            اگر هنوز فرصت شغلی مناسبی پیدا نکرده‌اید، خوشحال می‌شویم رزومه شما
            را بررسی کنیم و در نظر بگیریم که چگونه مهارت‌های شما می‌تواند برای
            سازمان ما ارزشمند باشد.
          </p>
          <Link to="/Fa/form">
            <button className="border border-green-400 py-3 px-8 rounded-3xl">
              ارسال رزومه
            </button>
          </Link>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Job;
