const AboutSlogun = () => {
  return (
    <>
      <div className="md:w-1/2 w-11/12 mx-auto py-0 md:py-10">
        <p className="text-white text-3xl font-semibold mt-4 mb-10 rtl">
          ما در حال ساخت دنیای جدیدی هستیم !
        </p>
        <p className="text-white text-base lg:text-xl rtl mb-10">
          ما به دنبال ارائه محصولات با کیفیت، با استانداردهای بین المللی در
          کلیه زنجیره های خلق ارزش هستیم.
        </p>
        <img
          src={require("../../../../Images/aboutpage.JPG")}
          className="mx-auto my-4 rounded-2xl"
        ></img>
        <p className="text-white text-base lg:text-xl my-10 rtl">
          رویکرد ما نوآوری در صنعت، ایجاد یک زنجیره تامین اثربخش، و حفظ برتری در
          توزیع محصولات است. و ما به دنیال ارائه محصولات با کیفیت با استاندارد
          های بین المللی در کلیه زنجیره های خلق ارزش هستیم.
        </p>
      </div>
    </>
  );
};
export default AboutSlogun;
