import React, { useState } from "react";
import productitem from "../productsData";
import { Link } from "react-router-dom";
const Filter = () => {
  const [productlist, setProductlist] = useState(productitem);
  const filterProduct = (id) => {
    if (id != 0) {
      const bproducts = productitem;
      const filterdata = bproducts.filter(
        (bproduct) => bproduct.collection == id
      );
      setProductlist(filterdata);
    } else {
      setProductlist(productitem);
    }
  };
  return (
    <>
      <div className="inline-block my-10">
        <button
          id="0"
          className="border bg-[#002B18] border-[#002B18] rounded-3xl text-white md:text-base px-2 py-4 mx-2 mb-2"
          onClick={() => filterProduct(0)}
        >
          All Products
        </button>
        <button
          id="A"
          className="border border-[#002B18] rounded-3xl text-white text-base px-2 py-3 mb-2"
          onClick={() => filterProduct("A")}
        >
          <img
            src={require("../../../Images/TestTube.png")}
            className="w-8 inline-block"
          ></img>
          Chemicals & Acids
        </button>
        <button
          id="B"
          className="border border-[#002B18] rounded-3xl text-white text-base px-2 py-3 mx-2 mb-2"
          onClick={() => filterProduct("B")}
        >
          <img
            src={require("../../../Images/NaturalFood.png")}
            className="w-8 inline-block"
          ></img>
          Fertilizer
        </button>
        <button
          id="C"
          className="border border-[#002B18] rounded-3xl text-white text-base px-2 py-3 mx-2 mb-2"
          onClick={() => filterProduct("C")}
        >
          <img
            src={require("../../../Images/CowBreed.png")}
            className="w-8 inline-block"
          ></img>
          Livestock feed additives
        </button>
      </div>
      <div className="p-5 container text-left w-full mx-auto">
        <div className="product container mx-auto w-full">
          {productlist?.map((product) => {
            return (
              <div
                id={product.id}
                className="w-1/2 mb-6 lg:w-1/4  inline-block"
                category_id={product.categoryId}
                key={product.id}
              >
                <div className="p1 w-11/12">
                  <Link to={`/products/${product.id}`}>
                    <img
                      className="w-full xl:rounded-t-3xl rounded-t-lg"
                      src={product.coverimage}
                    ></img>
                  </Link>
                </div>

                <div className="w-11/12 border-[#143591] border rounded-b-lg p-3 pt-0">
                  <Link to={`/products/${product.id}`}>
                    <p className="text-xs md:text-lg text-left text-white">
                      {product.titleb}
                    </p>
                  </Link>
                  <p className="text-left text-[10px] md:text-base text-[#C0BFBA]">
                    {product.title}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Filter;
