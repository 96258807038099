import React from "react";
import { Link, useParams } from "react-router-dom";
// import Header from "../Header/Header";
import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
// import inDetailImg from "../../../../Images/Rectangle.png";
import InActionData from "../../InAction/InActionData";
import EastIcon from "@mui/icons-material/East";
import Table from "./table";
import State from "../../InAction/StateData";
const InActionDetail = () => {
  const { inActionId } = useParams();
  const thisAction = InActionData.find((prod) => prod.id === inActionId);
  return (
    <>
      <div className="bgdetail PersianFont rtl">
        <Header />
        <div className="px-5 container mx-auto text-center mt-10">
          <div
            className="p-5 w-full 2xl:w-10/12 mx-auto my-5"
            key={thisAction.id}
          >
            <div className="text-right inline-block md:w-1/2 w-full">
              <Link to="/Fa/InAction">
                <EastIcon className="text-[#2159f2] inline-block ml-2" />
              </Link>
              <p className="text-gray-500 text-base rtl inline-block">
                {thisAction.datefa}
              </p>
              <h3 className="text-white md:text-3xl text-lg font-medium mb-4">
                {thisAction.titlefa}
              </h3>
              <p className="text-white md:text-lg text-md font-medium ">
                {thisAction.descfa}
              </p>
            </div>
            <div className="pic w-full md:w-1/2 inline-block">
              <img className="w-4/5" src={thisAction.image}></img>
            </div>
          </div>
          <div className="p-5 w-full 2xl:w-10/12 mx-auto my-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {State?.map((state) => {
                return state.id === thisAction.StateId ? (
                  <div className="text-right" key={state.id}>
                    <div className="text-green-600 text-xs w-full pr-1 mb-2">
                      <p>{state.titlefa}</p>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
                      <div className="bg-green-600 h-1.5 rounded-full dark:bg-blue-500"></div>
                    </div>
                  </div>
                ) : (
                  <div className="text-right" key={state.id}>
                    <div className="text-gray-600 text-xs w-full pr-1 mb-2">
                      <p>{state.titlefa}</p>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
                      <div className="bg-gray-600 h-1.5 rounded-full"></div>
                    </div>
                  </div>
                );
              })}
            </div>
            <p className="text-white text-xl md:text-2xl leading-10 my-10">
              {thisAction.maindescfa}
            </p>
            <img className="w-4/5 mx-auto" src={thisAction.DetailImg}></img>
            <p className="text-white text-xl md:text-2xl leading-10 my-10">
              {thisAction.techdescfa}
            </p>
            <Table inActionId={inActionId} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default InActionDetail;
