import { useParams } from "react-router-dom";

const Table = () => {
  const { inActionId } = useParams();
  return inActionId == 1 ? (
    <div className="text-white flex items-center justify-center">
      <table className="border-collapse w-4/5 my-5 mx-auto text-right border border-[#2159f2] min-h-52">
        <thead className="p-3">
          <tr>
            <th>ابعاد کلی</th>
            <th>عرض 11 متر</th>
            <th>طول 36 متر</th>
            <th>ارتفاع 29 متر</th>
          </tr>
        </thead>
        <tbody className="p-3">
          <tr className="border border-[#2159f2]">
            <td>حجم بتن‌ریزی</td>
            <td>750 متر مکعب</td>
            <td>حجم آرماتور</td>
            <td>120 تن</td>
          </tr>
          <tr className="border border-[#2159f2]">
            <td>قالب‌بندی</td>
            <td>2250 متر مربع</td>
            <td>حجم داربست</td>
            <td>7500 مترمکعب</td>
          </tr>
          <tr className="border border-[#2159f2]">
            <td>نفرساعت پیمانکاران</td>
            <td>35000</td>
            <td>حادثه ناشی از کار</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="text-white flex items-center justify-center">
      <table className="border-collapse w-full md:w-4/5 my-5 mx-auto text-right border border-[#2159f2] min-h-[300px] md:min-h-52">
        <thead className="p-3">
          <tr>
            <th>ابعاد کلی</th>
            <th>عرض 92 متر</th>
            <th>طول 120 متر</th>
            <th>ارتفاع 17 متر</th>
          </tr>
        </thead>
        <tbody className="p-3">
          <tr className="border border-[#2159f2]">
            <td>حجم بتن‌ریزی</td>
            <td>10000 متر مکعب</td>
            <td>حجم آرماتور</td>
            <td>850 تن</td>
          </tr>
          <tr className="border border-[#2159f2]">
            <td>قالب‌بندی</td>
            <td>12000 متر مربع</td>
            <td>حجم داربست</td>
            <td>30000 مترمکعب</td>
          </tr>
          <tr className="border border-[#2159f2]">
            <td>نفرساعت پیمانکاران</td>
            <td>135000</td>
            <td>حادثه ناشی از کار</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default Table;
