import icon1 from "../../../Images/TestTube.png";
import icon2 from "../../../Images/NaturalFood.png";
import icon3 from "../../../Images/CowBreed.png";

let Category = [
  {
    id: 1,
    icon: icon1,
    title: "Chemicals & Acids",
    desc: "Acids and chemical products are essential in the production of a wide range of products due to their unique chemical properties and their key role in advancing various industrial technologies.",
    num: "2 available products",
  },
  {
    id: 2,
    icon: icon2,
    title: "Fertilizer",
    desc: "Triple super phosphate fertilizer, with the highest amount of phosphorus, is ideal for meeting the needs of agricultural land.",
    num: "2 available product",
  },
  {
    id: 3,
    icon: icon3,
    title: "Livestock feed additives",
    desc: "",
    num: "coming soon",
  },
];
export default Category;
