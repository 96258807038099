const AboutBox = () => {
  return (
    <>
      <div className="p-5 container w-full mx-auto">
        <div className="bg-[#00D777] w-full md:w-1/4 rounded-3xl text-right p-5 md:mr-4 mr-0 inline-block">
          <p className="text-4xl text-white font-semibold mb-8 md:mb-10 lg:mb-20 sm:text-3xl">
            درصد ۷۰
          </p>
          <p className="text-xl text-white font-medium mb-2">رضایت مشتری </p>
          <p className="text-white text-sm font-normal rtl">
            نرخ حفظ مشتری بالا و بازخورد مثبت گواهی بر تعهد ما به کیفیت و خدمات
            مشتری است.
          </p>
        </div>
        <div className="bg-[#D9D8D6] w-full md:w-1/4 rounded-3xl text-right p-5 md:mr-4 mr-0 mt-5 inline-block">
          <p className="text-4xl text-black font-semibold mb-8 md:mb-3 text-4xll lg:mb-4">
            +۴
          </p>
          <p className="text-xl text-black font-medium mb-2">نوع محصولات </p>
          <p className="text-black text-sm font-normal rtl">
            انتخاب گسترده ای از اسیدها و کودها که نیازهای مختلف صنعتی را تامین
            می کند.
          </p>
        </div>
        <div className="bg-[#2159F2] w-full md:w-1/4 rounded-3xl text-right p-5 md:mr-4 mr-0 mt-5 inline-block">
          <p className="text-4xl text-white font-semibold mb-12 md:mb-10 lg:mb-20">
            +۱۶
          </p>
          <p className="text-xl text-white font-medium mb-2">سال تجربه </p>
          <p className="text-white text-sm font-normal rtl">
            بیش از 10 سال تجربه صنعت در شیمی، اسید و تولید کود.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutBox;
