import { Route, Routes } from "react-router-dom";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Index from "./Home";
import Products from "./Products/Products";
import ProductDetail from "./Products/ProductsDetail";
import InAction from "./InAction/InAction";
import InActionDetail from "./InAction/InActionDetail";
import Job from "./Job/Job";
import JobForm from "./Job/JobForm";
import NotFound from "../NotFound/NotFound";
import Auction from "./Auction/Auction";
import Blog from "./Blog/Blog";
import Blogdetail from "./Blog/Blogdetail";
const Fabrowser = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Products/:productId" element={<ProductDetail />} />
        <Route path="/InAction" element={<InAction />} />
        <Route path="/InAction/:inActionId" element={<InActionDetail />} />
        <Route path="/job" element={<Job />} />
        <Route path="/form" element={<JobForm />} />
        <Route path="/auction" element={<Auction />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<Blogdetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
export default Fabrowser;
