import localization from "../../../Images/localization .png";
let Data = [
  {
    id: "1",
    date: "22 April",
    datefa: "03 اردیبهشت 1403",
    group: "News",
    groupfa: "اخبار",
    cover: localization,
    title:
      "The localization of 'Triple Super Phosphate' fertilizer has resulted in 37 million dollars in annual savings",
    titlefa:
      "۳۷ میلیون دلار صرفه‌جویی سالانه با بومی‌سازی کود «تریپل سوپر فسفات ",
    shortdesc:
      "Localization of 'Triple Super Phosphate' (TSP) fertilizer with an annual capacity of 100,000 tons, also known as 'black fertilizer,' is one of the richest types of phosphate chemical fertilizers.",
    shortdescfa:
      " بومی‌سازی کود «تریپل سوپر فسفات» (TSP) با ظرفیت سالانه ۱۰۰ هزار تن که به «کود سیاه» نیز شهرت دارد و از غنی ترین انواع کود شیمیایی فسفاته محسوب می شود.",
    desc: "",
    // "According to the government's information base, quoting the Vice President for Science, Technology, and Knowledge-Based Economy of the Presidency, Shegefti, the CEO of Kimiadaran Kavir Company, stated that this company is the largest producer of triple superphosphate fertilizer in the country, said: This phosphate fertilizer, It is solid and the reaction of phosphoric acid and phosphate soil is used to produce it. Triple superphosphate is usually made in GTSP granular form because it is easier to use." +
    // "Regarding the production method of triple super phosphate fertilizer, he added: The production of triple super phosphate is done in two stages; In the first stage, phosphoric acid is obtained from the reaction of sulfuric acid with phosphate soil apatite, and in the second stage, phosphoric acid is separated from gypsum and after concentration, it is poured again on the phosphate soil apatite; In this way, triple superphosphate is obtained." +
    // "About the benefits of Triple Super Phosphate fertilizer produced by KDK Company, he said: Triple Super Phosphate fertilizer has the highest concentration of phosphate among phosphate fertilizers so the amount of P205 available in it is more than 46%. Also, since about 90% of the phosphorus in its structure is soluble, it is easily available to the plant." +
    // "According to him, one of the other advantages of triple superphosphate fertilizer is its micro and macro nutrient elements for plants: calcium in the amount of 15% sulfate, iron in the amount of 2%, and other elements in the amount of 1%. Phosphate in this fertilizer strengthens the trunk of plants and increases their resistance against diseases and pests." +
    // "Shegefti also emphasized that the triple superphosphate fertilizer produced by this company is free of cadmium and other heavy metals compared to imported fertilizers that have high levels of heavy metals, especially cadmium." +
    // "He mentioned that this fertilizer is suitable for all types of garden, agricultural, and summer crops. He explained that before applying the fertilizer, it is important to conduct a soil analysis to determine the level of phosphorus deficiency, and then calculate the appropriate amount to use accordingly." +
    // "He elaborated on the usage of Triple Super Phosphate fertilizer: The method involves uniform spreading. Before planting, the fertilizer is evenly distributed and blended with the soil. This approach suits soils with low or medium phosphorus levels. It's less effective than the strip method, hence requiring two to four times more fertilizer for equivalent results. Consequently, employing the spreading and mixing method for high phosphorus requirements to boost yield is neither cost-effective nor practical." +
    // "Shegefti considered the strip method as another model of using this type of fertilizer and said about the implementation of this model: In the strip method, in the planting operation, phosphorus is placed in a strip near the seed. This fertilizer is usually placed 2.5-5 cm next to and below the seed row." +
    // "According to him, phosphate fertilizers must be used before planting and placed under the seeds, preferably using a seed drill. Usually, this compound is used for all types of soil with acidic and slightly alkaline pH ranges and is known as autumn fertilizer." +
    // "Shegefti also considered the advantage of this product over similar foreign samples as the low percentage of heavy elements in this type of fertilizers and said: In similar foreign samples, the percentage of heavy elements such as arsenic and cadmium is very high, but with the investment that In the field of research and development, we succeeded in reducing the amount and percentage of these heavy elements in the localization version." +
    // "According to him, according to the license, this company produces about 100,000 tons per year, which can be increased to 200,000 tons per year." +
    // "In the end, he pointed out: that the government imports 250 thousand tons of fertilizer per year, and we at KDK Company, by carrying out research and development projects and localization of this fertilizer, have been able to make the country free from the need to import it, and by localizing this product To save 37 million dollars in foreign currency annually.",
    descfa:
      "کود فسفاته، جامد بوده و برای تولید آن از واکنش اسید فسفریک و خاک فسفات استفاده می شود. به جهت سهولت استفاده، تریپل سوپر فسفات عمدتا به صورت گرانولی GTSP تولید می شود. " +
      "وی در خصوص روش تولید کود تربیل سوپر فسفات، افزود: ساخت تریپل سوپر فسفات طی دو مرحله انجام می شود؛ در مرحله نخست از واکنش اسید سولفوریک با آپاتیت خاک فسفات اسید فسفریک به دست می آید و در مرحله دوم اسید فسفریک را از گچ جدا کرده و پس از تغلیظ مجددا آن را روی آپاتیت خاک فسفات می ریزند؛ بدین ترتیب تریپل سوپر فسفات حاصل می شود." +
      "شگفتی درباره مزایای کود تریپل سوپر فسفات تولیدی شرکت کیمیاداران کویر گفت: کود تریپل سوپر فسفات، بیشترین غلظت فسفات را در بین کودهای فسفاته دارد به گونه ای که میزان P۲۰۵ در دسترس آن بیش از ۴۶ درصد است. همچنین از آنجا که حدود ۹۰ درصد از فسفر موجود در ساختار آن محلول است به راحتی در دسترس گیاه قرار می گیرد." +
      "به گفته او، از دیگر مزایای کود سوپر فسفات تریپل، برخورداری آن از عناصر میکرو و ماکرو مغذی برای گیاه کلسیم به میزان ۱۵ درصد سولفات به میزان دو درصد آهن به میزان یک درصد سایر عناصر است. فسفات موجود در این کود تنه گیاهان را تقویت کرده و مقاومت آنها را در برابر بیماریها و آفات را افزایش میدهد." +
      "شگفتی همچنین تاکید کرد: کود سوپر فسفات تریپل تولیدی این شرکت در مقایسه با کودهای وارداتی که دارای میزان فلزات سنگین بخصوص کادمیوم بالا هستند عاری از کادمیوم و سایر فلزات سنگین است." +
      "وی با بیان اینکه این کود برای تمام محصولات باغی و زراعی و صیفی مناسب است، افزود: برای استفاده از این کود، نخست،  بر اساس آنالیز خاک، میزان فقر فسفر مشخص شده سپس طبق آنالیز آن میزان استفاده مشخص می شود." +
      "وی در خصوص روش مصرف کود سوپر فسفات تربیل توضیح داد:روش مصرف کود، بر اساس پخش یکنواخت است. در این روش قبل از کشت کود بطور یکنواخت پخش شده و با خاک مخلوط می شود. این روش در خاک هایی که سطح فسفر آنها پایین یا متوسط باشد. کارایی کمتری نسبت به روش نواری دارد و بنابراین میزان مصرف کود در روش پخش کردن و مخلوط کردن با خاک بایستی دو تا چهار برابر باشد تا بتوان محصولی مساوی با روش نواری بدست آورد. بنابر این روش پخش کردن و مخلوط کردن با خاک هنگامیکه مقدار زیادی فسفر برای افزایش محصول مورد نیاز باشد، اقتصادی و عملی نیست ." +
      "شگفتی، روش نواری را مدل دیگر استفاده از این نوع کود ها دانست و در خصوص نحوه اجرایی کردن این مدل گفت: در روش نواری، فسفر هنگام عملیات کاشت بصورت نواری در نزدیک بذر قرار داده می شود. این کود معمولاً ۲.۵-۵cm در کنار و زیر ردیف بذر قرار داده می شود." +
      "به گفته او، کودهای فسفاتی حتما باید قبل از زمان کاشت و به صورت جای‌گذاری عمقی مصرف و ترجیحا با استفاده از دستگاه کودکار بذرکار در زیر بذور جای‌گذاری شود. معمولا این ترکیب برای انواع خاک با دامنه pH اسیدی و قلیایی ملایم مورد استفاده قرار می گیرد و به عنوان کود پاییزه شناخته می شود." +
      "شگفتی، همچنین مزیت این محصول را نسبت به نمونه های مشابه خارجی، درصد پائین عناصر سنگین در این نوع از کودها دانست و گفت: در نمونه های مشابه خارجی، درصد عناصر سنگین اعم از آرسنیک و کادمیوم بسیار بالا است ولی با سرمایه گذاری ای که در حوزه تحقیق و توسعه انجام دادیم، موفق شدیم تا در نسخه بومی سازی میزان و درصد این عناصر سنگین را کاهش دهیم." +
      "به گفته او، این شرکت طبق پروانه چیزی حدود ۱۰۰هزار تن در سال تیراژ تولید دارد که  تا ۲۰۰ هزار تن در سال هم قابل افزایش است." +
      "وی در پایان خاطرنشان کرد: دولت ۲۵۰ هزار تن در سال کود وارد می کند و ما در شرکت کیمیاداران کویر با انجام طرح های تحقیق و توسعه و بومی سازی این کود توانسته ایم کشور را از واردات آن  بی نیاز کنیم  و با بومی سازی این محصول  ۳۷ میلیون دلار سالانه صرفه جویی ارزی را رقم بزنیم.",
  },
  {
    id: "2",
    date: "1 May",
    datefa: "12 اردیبهشت 1403",
    group: "News",
    groupfa: "اخبار",
    cover: "",
    title:
      "KimiaDaran Kavir's company has obtained a research and development license.",
    titlefa: "اخذ پروانه تحقیق و توسـعه توسط شرکت کیمیا داران کویر",
    shortdesc:
      "KDK Company succeeded in obtaining a research and development license from the Ministry of Industry, Mines, and Trade",
    shortdescfa:
      "شرکت کیمیا داران کویر موفق به اخذ پروانه تحقیق و توسعه از سوی وزارت صنعت ،معدن و تجارت گردید",
    desc:
      "The current world's growth will lead them to follow the path of development in a shorter time if they build industry knowledge and technology. In manufacturing companies, one of the demands for advancing social knowledge and transitioning from a single-product economy to the research and development category is the development of social knowledge. The main factor is the optimization and appropriate use of natural resources to create products with high added value and compete at the international level, and as a result, the realization of desirable economic growth." +
      "KimiaDaran Kavir’s company has successfully obtained a research and development license from the Ministry of Industry, Mines and Trade. The license is issued by the Provincial Industries and Mines Organization, following expert evaluation and meeting specific requirements (such as organizational structure, laboratory and specialized equipment, facilities, and skilled personnel), as well as completing a specialized training program for managers. The research and development license is awarded to holders of research and development certificates." +
      "This achievement is a result of the collective efforts of all our colleagues.",
    descfa:
      "در دنیای رو به رشد فعلی کشورهایی مسیر توسعه را در زمان کوتاهتری خواهند پیمود که صنعتی مبتنی بر دانش و فناوری بنا نهاده باشند. یکی از الزامات توسعه دانش محور و رهـایی از اقتصاد تک محصولی اعتنای ویژه به مقوله تحقیق و توسعه در شرکت های تولیدی می‌باشد.چراکه عامل اصلی بهینه سازی فرآیندها و به کارگیری مناسب منابع طبیعی در جهت ایجاد محصولات با ارزش افزوده بالا و رقابت پذیر در سطح بین الملل و درنتیجه تحقق رشد اقتصادی مطلوب می باشد." +
      "شرکت کیمیا داران کویر موفق به اخذ پروانه تحقیق و توسعه از سوی وزارت صنعت ،معدن و تجارت گردید. پروانه تحقیق و توسعه مدرکی است از سوی سازمان صنایع و معادن استان ها که پس از بررسی کارشناسی و دارا بودن شرایط لازم( از نظر تشکیلات سازمانی، تجهیزات و امکانات آزمایشگاهی و تخصصی، نیروی انسانی متخصص ) و پس از طی دوره آموزشی تکمیلی ویژه مدیران تحقیق و توسعه برای دارندگان گواهی تحقیق و توسعه صادر می گردد. " +
      "این موفقیت مدیون تلاش تمامی همکاران ما در مجموعه می‌باشد",
  },
  {
    id: "3",
    date: "",
    datefa: "",
    group: "Chemicals & Acids",
    groupfa: "مواد شیمیایی و اسیدها",
    cover: "",
    title: "What is sulfuric acid?",
    titlefa: "اسید سولفریک چیست؟",
    shortdesc:
      "Sulfuric acid is a potent acid that holds great significance in chemistry. Phosphoric acid production is its main use, which serves as a precursor for phosphate fertilizers like calcium dihydrogen sulfate and ammonium phosphate.",
    shortdescfa:
      "اسید سولفوریک، اسیدی بسیار قوی است و مهم ترین مواد شیمیایی در جهان است. بیشترین مصرف این محصول برای تولید اسید فسفریک است که به نوبه خود برای تهیه کودهای فسفاته شامل کلسیم دی هیدروژن سولفات و آمونیوم فسفات مورد استفاده قرار می گیرد",
    desc: "Additionally, sulfuric acid is essential for the manufacture of ammonium sulfate fertilizer, which is essential for replenishing sulfur levels in the soil. Due to its high exothermic properties and easy water dissolution, this acid generates substantial heat during the reaction. Sulfuric acid reacts with metals, with the rate of response intensifying at elevated temperatures. As a hazardous substance, sulfuric acid is subject to rigorous regulation.  This product is manufactured by Kimia Daran Kavir Company, which is among its largest producers throughout the country.",
    descfa:
      "اسید سولفوریک جهت تولید کود آمونیوم سولفات که برای جبران گوگرد در خاک مناسب است استفاده می شود. این اسید بسیار گرما زاست و با هر درصدی در آب حل می شود. در اثر واکنش با آب گرمای زیادی تولید می کند. اسید سولفوریک با فلزات واکنش می دهد و هر چه دما بالاتر باشد میزان این واکنش بیشتر خواهد شد. اسید سولفوریک در لیست مواد خطرناک قرار گرفته است. شرکت کیمیا داران کویر یکی از بزرگترین تولید کننده های این محصول در کشور است.",
  },
  {
    id: "4",
    date: "",
    datefa: "",
    group: "Chemicals & Acids",
    groupfa: "مواد شیمیایی و اسیدها",
    cover: "",
    title: "What is phosphoric acid?",
    titlefa: "اسید فسفریک چیست؟",
    shortdesc:
      "phosphoric acid is used in the production of agricultural fertilizers to meet the needs of plants and also meet the needs of livestock and poultry.",
    shortdescfa:
      "اسید فسفریک به علت وجود عنصر فسفر در ساختار خود، عمدتا برای تولید کودهای کشاورزی به منظور تامین فسفر مورد نیاز گیاهان و نیز برای تامین فسفر مورد نیاز دام و طیور کاربرد دارد.",
    desc:
      "Phosphoric acid is a weak mineral acid that is extracted from phosphate rock. The chemical composition of phosphoric acid is H3PO4 and it weighs 97.994 g/mol. Due to the presence of phosphorus in its structure," +
      "According to purity, phosphoric acid is divided into three categories: Industrial Grade, Feed Grade, and Food Grade. Phosphoric acid produced by Kimidaran Kavir Company has purity in feed grade." +
      "Phosphoric acid is produced in both heat and wet forms." +
      "Wet processes are more cost-effective and environmentally friendly due to the lower energy requirements.",
    descfa:
      "اسید فسفریک یک اسید معدنی ضعیف است که از سنگ فسفاته استخراج می شود. فرمول شیمیایی فسفریک اسید H3PO4 و جرم ملکولی آن 97.994 گرم بر مول می باشداین محصول که گاه آن را بصورت فسفوریک اسید یا اسید فسفوریک نیز نگارش می کنند، با نام هایی نظیر فسفریک اسید و ارتو فسفریک اسید نیز شناخته می شود. اسید فسفریک را بر حسب خلوص به سه دسته گرید صنعتی (Industrial Grade)، گرید خوراک دام و طیور (Feed Grade) و گرید خوراک انسان(Food Grade). اسید فسفریک تولیدی شرکت کیمیاداران کویر دارای خلوص در گرید دام و طیور است. . اسید فسفریک به دو شیوه ی گرمایی و تر تولید می گردد. با توجه به نیازمندی به میزان مصرف انرژی فرآیند های تر از لحاظ اقتصادی به صرفه تر هستند و از لحاظ محیط زیستی نیز بیشتر دوست دار محیط زیست محسوب می شوند.",
  },
  {
    id: "5",
    date: "",
    datefa: "",
    group: "Fertilizer",
    groupfa: "کود",
    cover: "",
    title: "Black manure: the richest phosphate chemical fertilizer",
    titlefa: "کود سیاه: غنی ترین کود شیمیایی فسفاته ",
    shortdesc:
      "Triple Super Phosphate TSP, which is also known as black fertilizer, is the richest type of phosphate chemical fertilizer that is used to provide phosphorus in soils with low phosphorus levels.",
    shortdescfa:
      "تریپل سوپر فسفات TSP، که به کود سیاه نیز شهرت دارد غنی ترین نوع کود شیمیایی فسفاته است که برای تامین فسفر در خاک‌هایی که سطح فسفر آن‌ها پائین است مورد استفاده قرار می گیرد.",
    desc:
      " KDK is the largest producer of this fertilizer with a capacity of 100 tons per annum. This phosphate fertilizer is solid and the reaction of phosphoric acid and phosphate rock is used to produce it. For ease of use, triple superphosphate is mainly produced in granular form, triple superphosphate fertilizer in granular form is abbreviated as GTSP." +
      "Fertilizer is generally defined as 'any organic or inorganic substance, natural or synthetic, that supplies one or more chemical elements required for plant growth.' Most agricultural fertilizers contain three main elements needed by plants: nitrogen, phosphorus, and potassium.",
    descfa:
      "شرکت کیمیاداران کویر بزرگترین تولید کننده در کشور با ظریقت سالانه 100 هز تن است. این کود فسفاته، جامد بوده و برای تولید آن از واکنش اسيد فسفريك و سنگ فسفات استفاده می شود. به جهت سهولت استفاده، تریپل سوپر فسفات عمدتا به صورت گرانولی تولید می شود،کود تریپل سوپر فسفات در حالت گرانوله را به صورت اختصاری با GTSP بیان می کنند. " +
      "کود به طور کلی به عنوان 'هر ماده ای آلی یا معدنی، طبیعی یا مصنوعی که یک یا چند عنصر شیمیایی مورد نیاز برای رشد گیاه را تامین می کند' تعریف می شود. بیشتر کودهای کشاورزی شامل سه عنصر اصلی مورد نیاز گیاه هستند: نیتروژن، فسفر و پتاسیم. ",
  },
];

export default Data;
