import images1 from "../../../../Images/sa1.jpg";
import imagesb1 from "../../../../Images/bsa1.jpg";
import images20 from "../../../../Images/sa20.jpg";
import imagesb20 from "../../../../Images/bsa20.jpg";
import image2 from "../../../../Images/ssf.jpg";
import image3 from "../../../../Images/sp.jpg";
import imagep4 from "../../../../Images/pa1.jpg";
import imagepb4 from "../../../../Images/bpa1.jpg";
import imagep5 from "../../../../Images/pa5.jpg";
import imagepb5 from "../../../../Images/bpa5.jpg";
import imagep20 from "../../../../Images/pa20.jpg";
import imagepb20 from "../../../../Images/bpa20.jpg";

let productitem = [
  {
    id: "1",
    categoryId: "1",
    coverimage: images1,
    image: [images1, imagesb1, images20, imagesb20],
    titleb: "اسید سولفوریک",
    title: "مواد شیمیایی و اسیدها",
    collection: "A",
    desc:
      " ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﻳﻚ ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ ﺑﺎ ﺧﻠﻮﺹ ﺑﺎﻻﻯ ٩٨٪ﺟﺰﻭ ﺑﻬﺘﺮﻳﻦ ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﺭﻳﻚ ﻫﺎﻯ ﺗﻮﻟﻴﺪی ﺍﻳﺮﺍﻥ ﻣﻰ ﺑﺎﺷﺪ." +
      "ﺍﻳﻦ ﺍﺳﻴﺪ ﺑﻪ ﺟﺰ ﻛﺎﺭﺑﺮﺩ ﺩﺭ ﺗﻮﻟﻴﺪ ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﺩﺭ ﺻﻨﺎﻳﻊ ﻣﺨﺘﻠﻒ ﻣﺎﻧﻨﺪ، ﺗﻮﻟﻴﺪ ﻣﻮﺍﺩ ﺷﻮﻳﻨﺪﻩ، ﺗﻮﻟﻴﺪ ﻛﻮﺩﻫﺎﻯ ﺷﻴﻤﻴﺎﻳﻰ" +
      "ﺳﻮﻟﻔﺎﺗﻪ، ﺻﻨﺎﻳﻊ ﻣﻌﺪﻧﻰ ﻭ ﻓﻠﺰﻯ ﻧﻴﺰ ﻛﺎﺭﺑﺮﺩ ﺩﺍﺭﺩ. ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ بزرگترین ﺗﻮﻟﻴﺪ ﻛﻨﻨﺪﻩ ﺍﻳﻦ ﻣﺤﺼﻮﻝ ﺩﺭ ﻛﺸﻮﺭ ﺑﺎ ﻇﺮﻓﻴﺖ ۲۶۰ ﻫﺰﺍﺭ ﺗﻦ ﺩﺭ ﺳﺎﻝ ﺍﺳﺖ.",
  },
  {
    id: "2",
    categoryId: "2",
    coverimage: image2,
    image: [image2],
    titleb: "سدیم سیلیکو فلوراید",
    title: "کود",
    collection: "B",
    desc:
      "ﺳﺪﻳﻢ ﺳﻴﻠﻴﻜﻮ ﻓﻠﻮﺭﺍﻳﺪ ﻳﻚ ﻣﺎﺩﻩ ﻛﺮﻳﺴﺘﺎﻟﻰ ﺳﻔﻴﺪ ﺭﻧگ ﺑﻪ ﺷﻜﻞ پودری ﺍﺳﺖ ﻛﻪ ﺍﻳﻦ ﻣﺎﺩﻩ ﺩﺭ ﺻﻨﻌﺖ ﻫﺎﻯ ﻣﺨﺘﻠﻔﻰ همچون،" +
      "ﺻﻨﻌﺖ ﺑﻠﻮﺭ و چینی، ﺻﻨﻌﺖ ﻛﺎﺷﻰ ﻭ ﺳﺮﺍﻣﻴﻚ ﻭ ﺻﻨﺎﻳﻊ ﻣﺨﺘﻠﻒ دیگری ﻛﺎﺭﺑﺮﺩ ﺍﺳﺎﺳﻰ ﺩﺍﺭﺩ." +
      "ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ بزرگترین ﺗﻮﻟﻴﺪ ﻛﻨﻨﺪﻩ ﺍﻳﻦ ﻣﺤﺼﻮﻝ ﺩﺭ ﻛﺸﻮﺭ ﺑﺎ ﻇﺮﻓﻴﺖ ۴۵۰۰ ﺗﻦ ﺩﺭ ﺳﺎﻝ ﺍﺳﺖ.",
  },
  {
    id: "3",
    categoryId: "3",
    coverimage: image3,
    image: [image3],
    titleb: "سوپر فسفات تریپل ",
    title: "کود",
    collection: "B",
    desc:
      "سوپر فسفات تریپل TSP ، " +
      "که به کود سیاه نیز شهرت دارد غنی ترین نوع کود شیمیایی فسفاته است که برای تامین فسفر" +
      "در خاک هایی که سطح فسفر آن ها پایین است مورد استفاده قرار می گیرد." +
      "شرکت کیمیاداران کویر بزرگترین تولید کننده این محصول در کشور با ظرفیت 100هزار تن در سال است",
  },
  {
    id: "4",
    categoryId: "4",
    coverimage: imagep4,
    image: [imagep4, imagepb4, imagep5, imagepb5, imagep20, imagepb20],
    titleb: "اسید فسفریک",
    title: "مواد شیمیایی و اسیدها",
    collection: "A",
    desc:
      "ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﺑﻪ ﻋﻨﻮﺍﻥ پر ﻣﺼﺮﻑ ﺗﺮﻳﻦ ﺍﺳﻴﺪ ﺑﻌﺪ ﺍﺯ ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﺭﻳﻚ ﺩﺭ ﺟﻬﺎﻥ ﺷﻨﺎﺧﺘﻪ می ﺷﻮﺩ." +
      "ﻭ ﺍﻳﻦ ﺍﺳﻴﺪ ﺩﺭ ﺳﻪ گرید ﻣﺘﻔﺎﻭﺕ ﺻﻨﻌﺘﻰ، ﺧﻮﺭﺍﻙ ﺩﺍﻡ ﻭ ﻃﻴﻮﺭ ﻭ گرید ﺧﻮﺭﺍﻙ ﺍﻧﺴﺎﻧﻰ ﺗﻘﺴﻴﻢ می ﺷﻮﺩ." +
      "ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ ﺩﺭ ﺩﺳﺘﻪ ﺍﺳﻴﺪ ﺧﻮﺭﺍﻙ ﺩﺍﻡ ﻭ ﻃﻴﻮﺭ ﻗﺮﺍﺭ می گیرﺩ." +
      "ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ بزرگترین ﺗﻮﻟﻴﺪ ﻛﻨﻨﺪﻩ ﺍﻳﻦ ﻣﺤﺼﻮﻝ ﺩﺭ ﻛﺸﻮﺭ ﺑﺎ ﻇﺮﻓﻴﺖ ۱۶۰ ﻫﺰﺍﺭ ﺗﻦ ﺩﺭ ﺳﺎﻝ ﺍﺳﺖ.",
  },
  {
    id: "5",
    categoryId: "5",
    coverimage: images20,
    image: [images20, imagesb20, images1, imagesb1],
    titleb: "اسید سولفوریک",
    title: "مواد شیمیایی و اسیدها",
    collection: "A",
    desc:
      " ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﻳﻚ ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ ﺑﺎ ﺧﻠﻮﺹ ﺑﺎﻻﻯ ٩٨٪ﺟﺰﻭ ﺑﻬﺘﺮﻳﻦ ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﺭﻳﻚ ﻫﺎﻯ ﺗﻮﻟﻴﺪی ﺍﻳﺮﺍﻥ ﻣﻰ ﺑﺎﺷﺪ." +
      "ﺍﻳﻦ ﺍﺳﻴﺪ ﺑﻪ ﺟﺰ ﻛﺎﺭﺑﺮﺩ ﺩﺭ ﺗﻮﻟﻴﺪ ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﺩﺭ ﺻﻨﺎﻳﻊ ﻣﺨﺘﻠﻒ ﻣﺎﻧﻨﺪ، ﺗﻮﻟﻴﺪ ﻣﻮﺍﺩ ﺷﻮﻳﻨﺪﻩ، ﺗﻮﻟﻴﺪ ﻛﻮﺩﻫﺎﻯ ﺷﻴﻤﻴﺎﻳﻰ" +
      "ﺳﻮﻟﻔﺎﺗﻪ، ﺻﻨﺎﻳﻊ ﻣﻌﺪﻧﻰ ﻭ ﻓﻠﺰﻯ ﻧﻴﺰ ﻛﺎﺭﺑﺮﺩ ﺩﺍﺭﺩ. ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ بزرگترین ﺗﻮﻟﻴﺪ ﻛﻨﻨﺪﻩ ﺍﻳﻦ ﻣﺤﺼﻮﻝ ﺩﺭ ﻛﺸﻮﺭ ﺑﺎ ﻇﺮﻓﻴﺖ ۲۶۰ ﻫﺰﺍﺭ ﺗﻦ ﺩﺭ ﺳﺎﻝ ﺍﺳﺖ.",
  },
  {
    id: "6",
    categoryId: "6",
    coverimage: imagep5,
    image: [imagep5, imagepb5, imagep4, imagepb4, imagep20, imagepb20],
    titleb: "اسید فسفریک",
    title: "مواد شیمیایی و اسیدها",
    collection: "A",
    desc:
      "ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﺑﻪ ﻋﻨﻮﺍﻥ پر ﻣﺼﺮﻑ ﺗﺮﻳﻦ ﺍﺳﻴﺪ ﺑﻌﺪ ﺍﺯ ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﺭﻳﻚ ﺩﺭ ﺟﻬﺎﻥ ﺷﻨﺎﺧﺘﻪ می ﺷﻮﺩ." +
      "ﻭ ﺍﻳﻦ ﺍﺳﻴﺪ ﺩﺭ ﺳﻪ گرید ﻣﺘﻔﺎﻭﺕ ﺻﻨﻌﺘﻰ، ﺧﻮﺭﺍﻙ ﺩﺍﻡ ﻭ ﻃﻴﻮﺭ ﻭ گرید ﺧﻮﺭﺍﻙ ﺍﻧﺴﺎﻧﻰ ﺗﻘﺴﻴﻢ می ﺷﻮﺩ." +
      "ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ ﺩﺭ ﺩﺳﺘﻪ ﺍﺳﻴﺪ ﺧﻮﺭﺍﻙ ﺩﺍﻡ ﻭ ﻃﻴﻮﺭ ﻗﺮﺍﺭ می گیرﺩ." +
      "ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ بزرگترین ﺗﻮﻟﻴﺪ ﻛﻨﻨﺪﻩ ﺍﻳﻦ ﻣﺤﺼﻮﻝ ﺩﺭ ﻛﺸﻮﺭ ﺑﺎ ﻇﺮﻓﻴﺖ ۱۶۰ ﻫﺰﺍﺭ ﺗﻦ ﺩﺭ ﺳﺎﻝ ﺍﺳﺖ.",
  },
  {
    id: "7",
    categoryId: "7",
    coverimage: imagep20,
    image: [imagep20, imagepb20, imagep4, imagepb4, imagep5, imagepb5],
    titleb: "اسید فسفریک",
    title: "مواد شیمیایی و اسیدها",
    collection: "A",
    desc:
      "ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﺑﻪ ﻋﻨﻮﺍﻥ پر ﻣﺼﺮﻑ ﺗﺮﻳﻦ ﺍﺳﻴﺪ ﺑﻌﺪ ﺍﺯ ﺍﺳﻴﺪ ﺳﻮﻟﻔﻮﺭﻳﻚ ﺩﺭ ﺟﻬﺎﻥ ﺷﻨﺎﺧﺘﻪ می ﺷﻮﺩ." +
      "ﻭ ﺍﻳﻦ ﺍﺳﻴﺪ ﺩﺭ ﺳﻪ گرید ﻣﺘﻔﺎﻭﺕ ﺻﻨﻌﺘﻰ، ﺧﻮﺭﺍﻙ ﺩﺍﻡ ﻭ ﻃﻴﻮﺭ ﻭ گرید ﺧﻮﺭﺍﻙ ﺍﻧﺴﺎﻧﻰ ﺗﻘﺴﻴﻢ می ﺷﻮﺩ." +
      "ﺍﺳﻴﺪ ﻓﺴﻔﺮﻳﻚ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ ﺩﺭ ﺩﺳﺘﻪ ﺍﺳﻴﺪ ﺧﻮﺭﺍﻙ ﺩﺍﻡ ﻭ ﻃﻴﻮﺭ ﻗﺮﺍﺭ می گیرﺩ." +
      "ﺷﺮﻛﺖ ﻛﻴﻤﻴﺎﺩﺍﺭﺍﻥ ﻛﻮﻳﺮ بزرگترین ﺗﻮﻟﻴﺪ ﻛﻨﻨﺪﻩ ﺍﻳﻦ ﻣﺤﺼﻮﻝ ﺩﺭ ﻛﺸﻮﺭ ﺑﺎ ﻇﺮﻓﻴﺖ ۱۶۰ ﻫﺰﺍﺭ ﺗﻦ ﺩﺭ ﺳﺎﻝ ﺍﺳﺖ.",
  },
];
export default productitem;
