// import Header from "../Header/Header";
import Header from "../Header/Navbar";
import AboutSlogun from "./AboutSlogun";
import Footer from "../Footer/Footer";
import AboutBox from "./AboutBox";
import Services from "../Services/Services";

const About = () => {
  return (
    <>
      <div className="bg-black text-center PersianFont">
        <Header />
        <AboutSlogun />
        <AboutBox />
        <p className="text-white text-base lg:text-xl mb-7 my-10 mx-auto w-10/12 md:w-1/2 rtl">
          شرکت کیمیا داران کویر فعالیت خود را از سال 1387 با هدف تولید نهاده‌های
          اساسی کشاورزی در یکی از مناطق محروم کشور آغاز نمود. تا استاندارها و
          نیازهای این صنعت را به بالاترین میزان ممکن تامین نماید محصولات ما شامل
          کود سوپر فسفات تریپل، اسید فسفریک و اسید سولفریک می‌باشد و در راستای
          توسعه پایدار مجموعه و تکمیل زنجیره تامین، شرکت کیمیا داران کویر شین با
          محوریت تولید و توزیع کودهای مایع و پودری با نام تجاری لندکو شروع به
          فعالیت نموده است.
        </p>
        <Services />
        <Footer />
      </div>
    </>
  );
};
export default About;
