import images1 from "../../Images/sa1.jpg";
import imagesb1 from "../../Images/bsa1.jpg";
import images20 from "../../Images/sa20.jpg";
import imagesb20 from "../../Images/bsa20.jpg";
import image2 from "../../Images/ssf.jpg";
import image3 from "../../Images/sp.jpg";
import imagep4 from "../../Images/pa1.jpg";
import imagepb4 from "../../Images/bpa1.jpg";
import imagep5 from "../../Images/pa5.jpg";
import imagepb5 from "../../Images/bpa5.jpg";
import imagep20 from "../../Images/pa20.jpg";
import imagepb20 from "../../Images/bpa20.jpg";

let productitem = [
  {
    id: "1",
    categoryId: "1",
    coverimage: images1,
    image: [images1, imagesb1, images20, imagesb20],
    titleb: "SULFURIC ACID 98.5%",
    title: "Chemicals & Acids",
    collection: "A",
    desc:
      "KDK’s sulfuric acid is among the purest sulfuric acids manufactured in Iran with a purity of more" +
      " than 98%." +
      "Apart from its use to produce phosphoric acid, it is commonly used in many industries. The " +
      "capacity for KDK’s sulfuric acid is 260K tonnes per annum.",
    bullet: "●	High Phosphorus Content",
  },

  {
    id: "2",
    categoryId: "2",
    coverimage: image2,
    image: [image2],
    titleb: "SODIUM SILICO FLUORIDE",
    title: "Fertilizer",
    collection: "B",
    desc:
      "SSF or sodium hexafluorosilicate is a fine, white, odorless, powdered solid. Toxic by ingestion," +
      " inhalation, and skin absorption. Used to alloy metals, opal glass, ore flotation, and water " +
      "fluoridation. " +
      "The production capacity for SSF is 5K tonnes per annum.",
    bullet: "",
  },
  {
    id: "3",
    categoryId: "3",
    coverimage: image3,
    image: [image3],
    titleb: "SUPER PHOSPHATE",
    title: "Fertilizer",
    collection: "B",
    desc:
      "Triple superphosphate is one of the first high-analysis phosphorus fertilizers. KDK is the " +
      "largest producer of this fertilizer with a capacity of 100K tonnes per annum.",
    bullet: "",
  },
  {
    id: "4",
    categoryId: "4",
    coverimage: imagep4,
    image: [imagep4, imagepb4, imagep5, imagepb5, imagep20, imagepb20],
    titleb: "PHOSPHORIC ACID 69%",
    title: "Chemicals & Acids",
    collection: "A",
    desc:
      "Phosphoric acid is one of the most commonly used acids in the world with three different grades: " +
      "industrial, feedstock, and food grade. " +
      "KDK’s phosphoric acid falls in the feedstock category with an annual capacity of 160K tonnes.",
    bullet: "",
  },
  {
    id: "5",
    categoryId: "5",
    coverimage: images20,
    image: [images1, imagesb1, images20, imagesb20],
    titleb: "SULFURIC ACID 98.5%",
    title: "Chemicals & Acids",
    collection: "A",
    desc:
      "KDK’s sulfuric acid is among the purest sulfuric acids manufactured in Iran with a purity of more" +
      " than 98%." +
      "Apart from its use to produce phosphoric acid, it is commonly used in many industries. The " +
      "capacity for KDK’s sulfuric acid is 260K tonnes per annum.",
    bullet: "●	High Phosphorus Content",
  },
  {
    id: "6",
    categoryId: "6",
    coverimage: imagep5,
    image: [imagep4, imagepb4, imagep5, imagepb5, imagep20, imagepb20],
    titleb: "PHOSPHORIC ACID 69%",
    title: "Chemicals & Acids",
    collection: "A",
    desc:
      "Phosphoric acid is one of the most commonly used acids in the world with three different grades: " +
      "industrial, feedstock, and food grade. " +
      "KDK’s phosphoric acid falls in the feedstock category with an annual capacity of 160K tonnes.",
    bullet: "",
  },
  {
    id: "7",
    categoryId: "7",
    coverimage: imagep20,
    image: [imagep4, imagepb4, imagep5, imagepb5, imagep20, imagepb20],
    titleb: "PHOSPHORIC ACID 69%",
    title: "Chemicals & Acids",
    collection: "A",
    desc:
      "Phosphoric acid is one of the most commonly used acids in the world with three different grades: " +
      "industrial, feedstock, and food grade. " +
      "KDK’s phosphoric acid falls in the feedstock category with an annual capacity of 160K tonnes.",
    bullet: "",
  },
];
export default productitem;
