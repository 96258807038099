import { Link } from "react-router-dom";
import Header from "../../header/Navbar";
import Footer from "../../Newfooter/newfooter";
import Blogdata from "./Blogdata";
const Gallery = () => {
  return (
    <>
      <div className="bgGallery text-center EnglishFont">
        <Header />
        <div className="w-2/3 mx-auto my-14 md:my-20 text-center">
          <div className="py-10 ">
            <p className="text-white font-bold mb-10 text-xl md:text-5xl">
              Read the latest news from KDK
            </p>
            <p className="text-lg w-4/5 text-white font-medium lg:w-1/2 mx-auto py-3">
              Stay up to date with news from across the KDK Group, from
              education to operations.
            </p>
          </div>
          <div className="flex flex-wrap">
            {Blogdata?.map((Data, index) => {
              return Data.cover === "" ? (
                <div key={index} className="w-full md:w-1/3 p-2">
                  <div className="flex flex-col justify-between border p-5 text-white rounded-3xl border-[#143591] text-left min-h-[380px] backdrop-blur h-full">
                    <div className="w-full">
                      <p className="text-base md:text-xs text-[#c0bfba] inline-block">
                        {Data.date}
                      </p>
                      <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-2 px-3 rounded-2xl text-center inline-block float-right">
                        {Data.group}
                      </p>
                    </div>
                    <p className="text-white w-full my-4 text-2xl md:text-xl">
                      {Data.title}
                    </p>
                    <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                      {Data.shortdesc}
                    </p>
                    <Link to={`/blog/${Data.id}`}>
                      <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                        More
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div key={index} className="w-full md:w-2/3 p-2">
                  <div className="flex border p-5 text-white rounded-3xl border-[#143591] text-left min-h-[380px] backdrop-blur">
                    <div className="w-1/2 flex items-center justify-center">
                      <img
                        src={Data.cover}
                        alt="cover"
                        className="w-3/5  rounded-3xl"
                      />
                    </div>
                    <div className="w-1/2 flex flex-col justify-between h-full">
                      <div className="w-full">
                        <p className="text-base md:text-xs text-[#c0bfba] inline-block">
                          {Data.date}
                        </p>
                        <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-2 px-3 rounded-2xl text-center inline-block float-right">
                          {Data.group}
                        </p>
                      </div>
                      <p className="text-white w-full my-4 text-2xl md:text-xl">
                        {Data.title}
                      </p>
                      <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                        {Data.shortdesc}
                      </p>
                      <Link to={`/blog/${Data.id}`}>
                        <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                          More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Gallery;
