import CheckIcon from "@mui/icons-material/Check";

const Services = () => {
  return (
    <>
      <div className="section5 mt-10">
        <p className="text-base text-[#00D777]">سرویس</p>
        <h2 className="text-xl text-white lg:mb-10 md:text-3xl">
          راه کار جدید
        </h2>

        <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 p-0 md:p-4 mx-1 text-right">
          <div className="s1 text-white rounded-3xl p-4 mx-1 text-right table w-full">
            <div className="table-row-group">
              <div className="blog table-cell">
                <p className="text-white w-full my-4 md:text-2xl text-xl">
                  هدف ما{" "}
                </p>
                <p className="text-[#c0bfba] font-normal md:text-base text-sm mb-4 rtl">
                  ارتقا استانداردهای صنعت فسفریک
                </p>
              </div>
              <div className="table-cell px-6">
                <CheckIcon className="text-[#00D777]" />
              </div>
            </div>
          </div>
        </div>
        <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 p-0 md:p-4 mx-1 text-right">
          <div className="s1 text-white rounded-3xl p-4 mx-1 text-right table w-full">
            <div className="table-row-group">
              <div className="blog table-cell">
                <p className="text-white w-full my-4 md:text-2xl text-xl">
                  ماموریت{" "}
                </p>
                <p className="text-[#c0bfba] font-normal md:text-base text-sm mb-4 rtl">
                  ارتقا استاندارهای کیفی صنعت مطابق با معیارهای جهانی
                </p>
              </div>
              <div className="table-cell px-6">
                <CheckIcon className="text-[#00D777]" />
              </div>
            </div>
          </div>
        </div>

        <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 p-0 md:p-4 mx-1 text-right">
          <div className="s1 text-white rounded-3xl p-4 mx-1 text-right table w-full">
            <div className="table-row-group">
              <div className="blog table-cell">
                <p className="text-white w-full my-4  md:text-2xl text-xl">
                  چشم انداز
                </p>
                <p className="text-[#c0bfba] font-normal md:text-base text-sm mb-4 rtl">
                  تولید کننده تاثیرگذار محصولات شیمیایی بر پایه فسفر
                </p>
              </div>
              <div className="table-cell px-6">
                <CheckIcon className="text-[#00D777]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
