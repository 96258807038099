import "../App/App.css";
// import Header from "../header/header";
import Header from "../header/Navbar";
//import Header from "../NewHeader/newheader";
import Slogun from "../slogun/slogun.js";
import Categories from "../categories/categories";
import Products from "../Products/productlist";
import About from "../about/about";
import Blog from "../blog/blog";
import Footer from "../Newfooter/newfooter";
import AuctionBanner from "../auctionBanner/AuctionBanner";
const Home = () => {
  return (
    <div className="bghome text-center EnglishFont">
      <Header />
      <Slogun />
      <Categories />
      <p className="text-base text-[#00D777]">PRODUCTS</p>
      <h2 className="text-xl lg:text-4xl text-white  mb-10 md:text-3xl">
        Our Latest Products
      </h2>
      <Products />
      <About />
      <Blog />
      <AuctionBanner />
      <Footer />
    </div>
  );
};
export default Home;
