import pic from "../../Images/project1.jpg";
import pic1 from "../../Images/project2.jpg";

let item = [
  {
    id: "1",
    title: "Second phosphoric rock warehouse",
    date: "Mar 16,2020",
    image: pic,
  },
  {
    id: "2",
    title: "Increasing concentration unit capacity",
    date: "June 16,2020",
    image: pic1,
  },
];
export default item;
