import pic from "../../../Images/project1.jpg";
import pic1 from "../../../Images/project2.jpg";
import pic2 from "../../../Images/project21.jpg";

let item = [
  {
    id: "1",
    StateId: "2",
    title: "Increasing concentration unit capacity",
    titlefa: "راه‌اندازی واحد افزایش  ظرفیت تغلیظ",
    date: "Mar 16,2020",
    datefa: "01 فروردین 1403",
    image: pic,
    DetailImg: "",
    desc: "Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad nostra, per inceptos himenaeos.",
    descfa:
      "با توجه به نیاز روزافزون به بهبود بهره‌وری و کاهش هزینه‌های نگه‌داری و تعمیرات، کارخانه کیمیاداران کویر پروژه‌ای کلیدی را برای افزایش ظرفیت تولید اسید فسفریک آغاز کرده است. این پروژه نه تنها به بهبود عملکرد صنعتی کمک می‌کند، بلکه با استفاده از تکنولوژی‌های پیشرفته، گامی مهم در جهت توسعه پایدار برداشته است.",
    maindesc: "",
    maindescfa:
      "پروژه افزایش ظرفیت تغلیظ اسید فسفریک در کارخانه کیمیاداران کویر با هدف افزایش تولید اسید فسفریک تا ۴۰۰ تن در روز طراحی شده است. برای دستیابی به این هدف، از تجهیزات و لوله‌های رابلاین با استفاده از مواد PP و PE جهت کنترل خوردگی و کاهش توقفات واحد، استفاده می‌شود.",
    techdesc: "",
    techdescfa:
      "پروژه افزایش ظرفیت تغلیظ اسید فسفریک در کارخانه کیمیاداران کویر، نمونه‌ای از تلاش‌های مستمر این کارخانه برای بهبود و توسعه پایدار است. با اجرای این پروژه، ظرفیت تولید افزایش می‌یابد، و با کاهش هزینه‌های نگه‌داری و تعمیرات، بهره‌وری نیز بهبود می‌یابد." +
      "این پروژه نشان‌دهنده تعهد کارخانه کیمیاداران کویر به استفاده از تکنولوژی‌های پیشرفته و توسعه پایدار است.",
  },
  {
    id: "2",
    StateId: "4",
    title: "Second phosphoric rock warehouse",
    titlefa: "ایجاد دومین انبار خاک",
    date: "June 16,2020",
    datefa: "01 فروردین 1403",
    image: pic1,
    DetailImg: pic2,
    desc: "Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad nostra, per inceptos himenaeos.",
    descfa:
      "پروژه احداث انبار خاک 211 با هدف بهبود و ارتقاء زیر ساخت های صنعتی شامل چند ویژگی برجسته و نوآورانه است که به افزایش کارایی و بهره‌وری و با هدف بهبود و افزایش ظرفیت ذخیره سازی خاک طراحی و اجرا می‌شود.",
    maindesc: "",
    maindescfa:
      "این پروژه با بهره‌گیری از سازه های فضایی سقف، امکان حذف ستون های وسط سالن را فراهم می‌کند که منجر به ایجاد فضای باز و گسترده تر می‌شود. این ویژگی به بهبود جریان کار و افزایش انعطاف پذیری در استفاده از فضا کمک می‌کند." +
      "یکی دیگر از ویژگی های مهم این پروژه نصب دو واحد آسیاب بال میل به قطر 2200 میلی‌متر است که برای تنظیم دانه بندی خاک به کار می‌رود." +
      "این تجهیزات پیشرفته به بهبود کیفیت و دقت در فرایند تولید کمک میکند و باعث افزایش کارایی و کاهش هزینه های تولید می‌شوند." +
      "همچنین احداث ساختمان برق و ترانس مجزا به ظرفیت 2 مگاوات، تامین انرژی پایدار و مطمئن برای واحدهای مختلف پروژه را تامین می‌کند." +
      "این اقدام به کاهش خطرات ناشی از نوسانات برق و افزایش پایداری عملیات تولید کمک می کند.",
    techdesc: "",
    techdescfa:
      "مدت زمان ساخت پروژه 8 ماه به طول انجامید که نشان دهنده برنامه ریزی دقیق و مدیریت کارامد در اجرای پروژه بوده است." +
      "با توجه به ویژگی های فنی و نوآروانه،  این پروژه نقش مهمی در بهبود زیرساخت های ذخیره سازی و مدیریت منابع خاک ایفا خواهد کرد و انتظار می‌رود بهبود قابل توجهی در عملکرد و بهره وری واحدهای صنعتی حاصل شود." +
      "یکی از نکات برجسته دراین پروژه عدم وقوع هیچ گونه حادثه ناشی از کار است که نشان دهنده رعایت دقیق اصول ایمنی و بهداشت حرفه‌ای توسط پیمانکاران می‌باشد.",
  },
];
export default item;
