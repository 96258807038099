import AboutData from "./AboutData";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles.css";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const About = () => {
  return (
    <>
      <div className="section4 p-5 mt-10 container mx-auto w-full">
        <p className="text-base text-[#00D777]">PROJECTS</p>
        <h2 className="md:text-4xl text-white  mb-14 text-2xl">
          Check Out Our Highlighted Projects
        </h2>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          breakpoints={{
            480: {
              slidesPerView: 1,
            },
            870: {
              slidesPerView: 1,
            },
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper text-white h-100"
        >
          {AboutData?.map((elem) => {
            return (
              <SwiperSlide key={elem.id}>
                <div className=" border rounded-2xl border-[#143591] p-5">
                  <div className="text-left inline-block md:w-1/2 w-full">
                    <h3 className="text-white md:text-4xl text-lg font-medium mb-3 ">
                      {elem.title}
                    </h3>
                    <p className="text-gray-500 text-base h-10 md:h-40">
                      {elem.date}
                    </p>
                    <button className="text-white mb-4 md:mb-0 border rounded-full py-2 px-5 border-[#2159F2] hover:bg-[#2159F2]">
                      Go to project
                    </button>
                  </div>
                  <div className="pic w-full md:w-1/2 inline-block">
                    <img src={elem.image}></img>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default About;
