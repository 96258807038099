// import Header from "../../header/header";
import Header from "../../header/Navbar";
import Footer from "../../Newfooter/newfooter";
import "../../../styles.css";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const JobForm = () => {
  const [data, setData] = useState({
    JobTitle: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Message: "",
    Address: "",
    BirthDate: "",
    BirthCity: "",
    CvFile: "",
  });
  // const setJobTitle = (e) => {
  //   console.log(data);
  //   setData({ ...data, JobTitle: e.target.value });
  // };
  const handleSelectChange = (event) => {
    console.log(event.target.value);
  };
  const setFirstName = (e) => {
    console.log(data);
    setData({ ...data, FirstName: e.target.value });
  };
  const setLastName = (e) => {
    console.log(data);
    setData({ ...data, LastName: e.target.value });
  };
  const setEmail = (e) => {
    console.log(data);
    setData({ ...data, Email: e.target.value });
  };
  const setPhone = (e) => {
    console.log(data);
    setData({ ...data, Phone: e.target.value });
  };
  const setMessage = (e) => {
    console.log(data);
    setData({ ...data, Message: e.target.value });
  };
  const setAddress = (e) => {
    console.log(data);
    setData({ ...data, Address: e.target.value });
  };
  const setBirthDate = (e) => {
    console.log(data);
    setData({ ...data, BirthDate: e.target.value });
  };
  const setBirthCity = (e) => {
    console.log(data);
    setData({ ...data, BirthCity: e.target.value });
  };
  const setCvFile = (e) => {
    console.log(data);
    setData({ ...data, CvFile: e.target.value });
  };

  // const MySwal = withReactContent(Swal);

  // const showAlert = (message) => {
  //   MySwal.fire({
  //     position: "center",
  //     icon: "error",
  //     title: message,
  //     showConfirmButton: true,
  //     confirmButtonText: "close",
  //     customClass: {
  //       title: "secondtitle",
  //       confirmButton: "confirmbutton",
  //     },
  //   });
  // };

  // const handleSubmit = () => {
  //   const {
  //     JobTitle,
  //     FirstName,
  //     LastName,
  //     Email,
  //     Phone,
  //     Address,
  //     BirthDate,
  //     BirthCity,
  //   } = data;

  //   if (
  //     !JobTitle ||
  //     !FirstName ||
  //     !LastName ||
  //     !Email ||
  //     !Phone ||
  //     !Address ||
  //     !BirthDate ||
  //     !BirthCity
  //   ) {
  //     showAlert("Complete all required fields");
  //     return;
  //   }

  //   MySwal.fire({
  //     position: "center",
  //     icon: "success",
  //     title: "Your information has been saved",
  //     showConfirmButton: false,
  //     timer: 2000,
  //     customClass: {
  //       title: "secondtitlefa",
  //     },
  //   });
  // };

  const SecondSwal = withReactContent(Swal);
  const showAlert = () => {
    SecondSwal.fire({
      position: "center",
      icon: "success",
      title: "Your information has been saved",
      showConfirmButton: false,
      timer: 2000,
      customClass: {
        title: "secondtitle",
      },
    });
  };

  return (
    <>
      <div className="bgjobform EnglishFont">
        <Header />
        <div className="w-10/12 md:w-1/2 mx-auto my-8 md:my-20">
          <div className="text-white text-center">
            <p className="md:text-4xl text-2xl font-semibold mb-10">
              Join Our Team of Experts
            </p>
            <p className="md:text-xl text-lg font-normal mb-12 lg:mb-40">
              After the initial review of the submitted resumes, if you meet the
              required qualifications, the company will invite you for a job
              interview. If you are interested in employment opportunities,
              please complete the form below.
            </p>
          </div>
          <div className="basis-full text-white text-sm md:text-base text-left block">
            <label htmlFor="title" className="font-medium">
              Job title
            </label>
            <select
              onChange={handleSelectChange}
              defaultValue={"placeholder"}
              className="w-full border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
            >
              <option
                disabled
                value={"placeholder"}
                className="w-full option text-[#2159F2]"
              >
                Select Job
              </option>
              <option
                // onChange={(e) => setJobTitle(e)}
                value="HRManager"
                className="w-full option text-[#2159F2]"
              >
                Human Resources Manager
              </option>
            </select>
          </div>
          <div className="flex mt-9">
            <div className="basis-1/2 text-white  text-sm md:text-base text-left block">
              <label htmlFor="firstname" className="font-medium w-full block">
                First Name
              </label>
              <input
                onChange={(e) => setFirstName(e)}
                type="text"
                id="firstname"
                name="fname"
                required
                className="w-[94%] border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
              />
            </div>
            <div className="basis-1/2 text-white  text-sm md:text-base text-left block">
              <label htmlFor="lastname" className="font-medium w-full block">
                Last Name
              </label>
              <input
                onChange={(e) => setLastName(e)}
                type="text"
                id="lastname"
                name="lname"
                required
                className="w-full border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
              />
            </div>
          </div>
          <div className="flex mt-9">
            <div className="basis-1/2 text-white  text-sm md:text-base text-left block">
              <label htmlFor="email" className="font-medium w-full block">
                Email Address
              </label>
              <input
                onChange={(e) => setEmail(e)}
                type="text"
                id="email"
                name="email"
                required
                className="w-[94%] border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
              />
            </div>
            <div className="basis-1/2 text-white  text-sm md:text-base text-left block">
              <label htmlFor="phone" className="font-medium w-full block">
                Phone Number
              </label>
              <input
                onChange={(e) => setPhone(e)}
                type="text"
                id="phone"
                name="phone"
                required
                className="w-full border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
              />
            </div>
          </div>
          <div className=" mt-9 basis-full text-white  text-sm md:text-base text-left block">
            <label htmlFor="address" className="font-medium w-full block">
              Address
            </label>
            <input
              onChange={(e) => setAddress(e)}
              type="text"
              id="address"
              name="address"
              required
              className="w-full border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
            ></input>
          </div>
          <div className="flex mt-9">
            <div className="basis-1/2 text-white  text-sm md:text-base text-left block">
              <label htmlFor="birth" className="font-medium w-full block">
                Birth Date
              </label>
              <input
                onChange={(e) => setBirthDate(e)}
                type="text"
                id="birth"
                name="birth"
                required
                className="w-[94%] border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-[45px]"
              />
            </div>
            <div className="basis-1/2 text-white  text-sm md:text-base text-left block">
              <label htmlFor="city" className="font-medium w-full block">
                Birth City
              </label>
              <input
                onChange={(e) => setBirthCity(e)}
                type="text"
                id="city"
                name="city"
                required
                className="w-full border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-2 min-h-11"
              />
            </div>
          </div>
          <div className="text-white  text-sm md:text-base mt-9 rounded-3xl">
            <label htmlFor="cv" className="font-medium w-full text-left">
              Upload CV
            </label>
            <div className="file-upload border border-dashed border-[#143591] relative text-center mt-2 min-h-56 ">
              <svg
                className="inline-grid"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_92_5643"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="40"
                  height="40"
                >
                  <rect width="40" height="40" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_92_5643)">
                  <path
                    d="M10.0003 36.6663C9.08366 36.6663 8.29894 36.34 7.64616 35.6872C6.99338 35.0344 6.66699 34.2497 6.66699 33.333V6.66634C6.66699 5.74967 6.99338 4.96495 7.64616 4.31217C8.29894 3.6594 9.08366 3.33301 10.0003 3.33301H23.3337L33.3337 13.333V33.333C33.3337 34.2497 33.0073 35.0344 32.3545 35.6872C31.7017 36.34 30.917 36.6663 30.0003 36.6663H10.0003ZM21.667 14.9997V6.66634H10.0003V33.333H30.0003V14.9997H21.667Z"
                    fill="#A6BDFA"
                  />
                </g>
              </svg>
              <div className="block">
                <p className="text-base font-medium text-[#2159f2] inline-block mr-1">
                  Upload a file
                </p>
                <p className="font-normal inline-block">or drag and drop</p>
                <p>DOC, PDF, TXT up to 5MB</p>
              </div>
              <input
                onChange={(e) => setCvFile(e)}
                type="file"
                id="file"
                name="file"
                accept=".pdf,.doc,.txt"
              />
            </div>
          </div>
          <div className="basis-full mt-9 rounded-3xl text-white text-sm md:text-base">
            <label htmlFor="message" className="font-medium w-full text-left">
              Your message
            </label>
            <textarea
              onChange={(e) => setMessage(e)}
              placeholder="Write here..."
              id="message"
              name="message"
              required
              className="w-full border border-[#2159f2] rounded-lg bg-transparent font-normal placeholder-[#c0bfba] text-white placeholder-opacity-100 focus:border-indigo-500 text-base outline-none py-1 px-3 transition-colors duration-200 ease-in-out mt-2"
              rows={8}
            ></textarea>
          </div>
          <button
            className="bg-[#00d777] text-black rounded-3xl py-3 px-8 w-full mt-9 text-base font-medium"
            onClick={() => showAlert()}
            // onClick={handleSubmit}
          >
            Lets talk
          </button>
        </div>
        {/* <div className="block text-white text-lg container mx-auto mt-5">
          <h5>JobTitle:{data.JobTitle}</h5>
          <h5>FirstName:{data.FirstName}</h5>
          <h5>LastName:{data.LastName}</h5>
          <h5>Email:{data.Email}</h5>
          <h5>Phone:{data.Phone}</h5>
          <h5>Message:{data.Message}</h5>
          <h5>BirthDate:{data.BirthDate}</h5>
          <h5>BirthCity:{data.BirthCity}</h5>
          <h5>CvFile:{data.CvFile}</h5>
        </div> */}
        <Footer />
      </div>
    </>
  );
};
export default JobForm;
