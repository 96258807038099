let item = [
  {
    id: "1",
    date: "03 اردیبهشت 1403",
    group: "اخبار",
    title:
      "37 میلیون دلار صرفه جویی سالانه با بومی سازی کود « تریپل سوپرفسفات»",
    desc: " بومی‌سازی کود «تریپل سوپر فسفات» (TSP) با ظرفیت سالانه ۱۰۰ هزار تن که به «کود سیاه» نیز شهرت دارد و از غنی ترین انواع کود شیمیایی فسفاته محسوب می شود.",
  },
  {
    id: "2",
    date: "",
    group: "مواد شیمیایی و اسیدها",
    title: "اسید سولفوریک چیست؟",
    desc: "اسید سولفوریک، اسیدی بسیار قوی است و مهم ترین مواد شیمیایی در جهان است. بیشترین مصرف این محصول برای تولید اسید فسفریک است که به نوبه خود برای تهیه کودهای فسفاته شامل کلسیم دی هیدروژن سولفات و آمونیوم فسفات مورد استفاده قرار می گیرد",
  },
  {
    id: "3",
    date: "12 اردیبهشت 1403",
    group: "اخبار",
    title: "اخذ پروانه تحقیق و توسـعه توسط شرکت کیمیا داران کویر",
    desc: "شرکت کیمیا داران کویر موفق به اخذ پروانه تحقیق و توسعه از سوی وزارت صنعت ،معدن و تجارت گردید",
  },
];

export default item;
