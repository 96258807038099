// import Header from "../Header/Header";
import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import Filter from "./Filter";

const Products = () => {
  return (
    <>
      <div className=" text-center PersianFont">
        <Header />
        <div className="bgproduct py-10">
          <p className="text-white text-3xl md:text-4xl font-semibold mb-10">
            محصولات ما
          </p>
          <p className="text-white mx-auto md:text-xl w-7/12 py-5 rtl">
            برای مشاهده جزئیات جامع محصولات و انجام فرایند ثبت سفارش خواهشمنداست
            بر روی نام محصول مورد نیاز کلیک نمایید
          </p>
          <Filter />
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Products;
