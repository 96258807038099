import pic from "../../../../../Images/project1.jpg";
import pic1 from "../../../../../Images/project2.jpg";

let item = [
  {
    id: "1",
    title: "Country Manager & Scaling the Entire organization of Japan",
    titlefa: "واحد افزایش ظرفیت تقلیظ ",
    date: "Mar 16,2020",
    datefa: "01 فروردین 1403",
    image: pic,
    desc: "Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad nostra, per inceptos himenaeos.",
    descfa: "",
  },
  {
    id: "2",
    title: "Norem ipsum dolor sit amet, consectetur adipiscing elit",
    titlefa: "افزایش ظرفیت انبار خاک ",
    date: "June 16,2020",
    datefa: "01 فروردین 1403",
    image: pic1,
    desc: "Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad nostra, per inceptos himenaeos.",
    descfa: "",
  },
];
export default item;
