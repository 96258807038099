import icon1 from "../../../../../Images/TestTube.png";
import icon2 from "../../../../../Images/NaturalFood.png";
import icon3 from "../../../../../Images/CowBreed.png";

let Category = [
  {
    id: 1,
    icon: icon2,
    title: "کود",
    desc: "کود تریپل سوپر فسفات با بالاترین میزان فسفر جهت رفع نیاز زمین های کشاورزی مناسب است.",
    num: "2 محصول در دسترس",
  },
  {
    id: 2,
    icon: icon1,
    title: "مواد شیمیایی و اسیدها",
    desc: "اسیدها و محصولات شیمیایی به دلیل خصوصیات شیمیایی منحصر به فردشان در تولید طیف وسیعی از محصولات کاربرد دارند و نقش کلیدی در پیشبرد فناوری‌های مختلف صنعتی ایفا می‌کنند.",
    num: "2محصول در دسترس",
  },

  {
    id: 3,
    icon: icon3,
    title: "خوراک دام و طیور",
    desc: "",
    num: "به زودی",
  },
];
export default Category;
