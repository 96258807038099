import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import Blogdata from "../../Blog/Blogdata";
import { Link } from "react-router-dom";
const Gallery = () => {
  return (
    <>
      <div className="bgGallery text-center PersianFont">
        <Header />
        <div className="w-2/3 mx-auto my-14 md:my-20 text-center rtl">
          <div className="py-10 ">
            <p className="text-white font-bold mb-10 text-xl md:text-5xl">
              از آخرین اخبار کیمیاداران کویر مطلع شوید.
            </p>
            <p className="text-lg w-4/5 text-white font-medium lg:w-1/2 mx-auto py-3">
              با اخبار کیمیاداران کویر ، از آموزش تا عملیات ، به روز باشید.
            </p>
          </div>
          <div className="flex flex-wrap">
            {Blogdata?.map((Data, index) => {
              return Data.cover === "" ? (
                <div key={index} className="w-full md:w-1/3 p-2">
                  <div className="flex flex-col justify-between border p-5 text-white rounded-3xl border-[#143591] text-right min-h-[380px] backdrop-blur h-full">
                    <div className="w-full">
                      <p className="text-base md:text-xs text-[#c0bfba] inline-block">
                        {Data.datefa}
                      </p>
                      <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-2 px-3 rounded-2xl text-center inline-block float-right">
                        {Data.groupfa}
                      </p>
                    </div>
                    <p className="text-white w-full my-4 text-2xl md:text-xl">
                      {Data.titlefa}
                    </p>
                    <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                      {Data.shortdescfa}
                    </p>
                    <Link to={`/Fa/blog/${Data.id}`}>
                      <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                        بیشتر
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div key={index} className="w-full md:w-2/3 p-2">
                  <div className="flex border p-5 text-white rounded-3xl border-[#143591] text-right min-h-[380px] backdrop-blur">
                    <div className="w-1/2 flex items-center justify-center">
                      <img
                        src={Data.cover}
                        alt="cover"
                        className="w-3/5  rounded-3xl"
                      />
                    </div>
                    <div className="w-1/2 flex flex-col justify-between h-full">
                      <div className="w-full">
                        <p className="text-base md:text-xs text-[#c0bfba] inline-block">
                          {Data.datefa}
                        </p>
                        <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-2 px-3 rounded-2xl text-center inline-block float-right">
                          {Data.groupfa}
                        </p>
                      </div>
                      <p className="text-white w-full my-4 text-2xl md:text-xl">
                        {Data.titlefa}
                      </p>
                      <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                        {Data.shortdescfa}
                      </p>
                      <Link to={`/Fa/blog/${Data.id}`}>
                        <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                          بیشتر
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="grid-cols-3 inline-grid gap-10">
            {Blogdata?.map((Data) => {
              return (
                <div className="grid border p-5 text-white rounded-3xl border-[#143591] text-right  min-h-[380px] backdrop-blur content-stretch">
                  <div className="inline-block w-full">
                    <p className="text-base md:text-xs text-[#c0bfba] w-1/3 lg:w-1/2 inline-block text-center">
                      {Data.datefa}
                    </p>
                    <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-1 px-1 rounded-2xl text-center w-1/2 inline-block float-start">
                      {Data.groupfa}
                    </p>
                  </div>
                  <p className="text-white w-full my-4 text-2xl md:text-xl">
                    {Data.titlefa}
                  </p>
                  <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                    {Data.shortdescfa}
                  </p>
                  <Link to={`/Fa/blog/${Data.id}`}>
                    <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                      بیشتر
                    </button>
                  </Link>
                </div>
              );
            })}
          </div> */}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Gallery;
