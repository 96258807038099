import { Link } from "react-router-dom";
// import Header from "../Header/Header";
import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import InActionData from "../../InAction/InActionData";

const InAction = () => {
  return (
    <>
      <div className="bgaction text-white PersianFont">
        <Header />
        <div className="container mx-auto my-10 md:my-20 lg:my-32 text-center">
          <h1 className="text-xl text-white font-bold mb-10 md:text-3xl lg:text-4xl">
            پروژه های در حال توسعه
          </h1>
          <p className="text-lg text-white font-medium w-11/12 lg:w-1/2 mx-auto py-3 rtl">
            ما به دنبال ارائه محصولات با کیفیت، با استانداردهای بین المللی در
            کلیه زنجیره های خلق ارزش هستیم.
          </p>
        </div>
        <div>
          {InActionData?.map((elem) => {
            return (
              <div className="px-5 container mx-auto text-center" key={elem.id}>
                <div className="border rounded-2xl border-[#143591] p-5 w-full 2xl:w-10/12 mx-auto my-5">
                  <div className="pic w-full md:w-1/2 inline-block">
                    <img className="w-4/5" src={elem.image}></img>
                  </div>
                  <div className="text-right inline-block md:w-1/2 w-full">
                    <h3 className="text-white md:text-2xl text-lg font-medium mb-3">
                      {elem.titlefa}
                    </h3>
                    <p className="text-gray-500 text-base h-10 md:h-40 rtl">
                      {elem.datefa}
                    </p>
                    <div className="text-center grid justify-end">
                      <Link
                        className="no-underline text-center"
                        to={`/Fa/InAction/${elem.id}`}
                      >
                        <h3 className="no-underline text-white w-40 mb-4 md:mb-0 border rounded-full py-2 px-5 border-[#2159F2] hover:bg-[#2159F2]">
                          بیشتر بدانید
                        </h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default InAction;
