import BlogData from "./BlogData";
const Blog = () => {
  return (
    <>
      <div className="section5 mt-10 container mx-auto w-full inline-grid">
        <p className="text-base text-[#00D777]">بلاگ و اخبار</p>
        <h2 className="md:text-3xl text-white text-xl mb-1 PersianBold rtl">
          آخرین اخبار ما را دنبال کنید
        </h2>
        <p className="text-neutral-200 md:text-base mb-10 text-xs">
          از آخرین اخبار کیمیا داران کویر مطلع شوید
        </p>{" "}
        <div className="justify-self-center">
          {BlogData?.map((blog) => {
            return (
              <div
                className="px-6 inline-block w-full lg:w-1/4 mx-1 mb-4 lg:mb-0 align-bottom"
                key={blog.id}
              >
                <div className="s1 border p-5 text-white rounded-3xl border-[#143591] text-right grid min-h-[330px]">
                  <div className="inline-block w-full">
                    <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-1 px-1 rounded-2xl text-center inline-block w-1/2">
                      {blog.group}
                    </p>
                    <p className="text-base md:text-xs text-[#c0bfba] rtl w-1/2 inline-block">
                      {blog.date}
                    </p>
                  </div>
                  <p className="text-white w-full my-4 text-xl lg:text-2xl rtl">
                    {blog.title}
                  </p>
                  <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4 rtl">
                    {blog.desc}
                  </p>
                  <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                    بیشتر
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {/*        
        <div className="px-6 inline-block w-full lg:w-1/4 mx-1 mb-4 lg:mb-0">
          <div className="s1 border p-5 text-white rounded-3xl border-[#143591] text-right">
            <div className="date inline-block w-1/3 lg:w-1/2">
              <p className="text-base md:text-xs text-[#c0bfba]">
                ۲۸ فروردین، ۱۴۰۳
              </p>
            </div>
            <div className="category inline-block w-1/2">
              <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-1 px-1 rounded-2xl text-center">
                کودها
              </p>
            </div>
            <div className="blog">
              <p className="text-white w-full my-4 text-2xl md:text-xl rtl">
                تحریک ریشه زایی و درشت شدن محصول.
              </p>
              <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4 rtl">
                فرمول فوق به علت اینکه هر ۴ فرم ازت را دارا میباشد در بازه زمانی
                ۱٫۵ ماهه که ازت اورهای نیاز دارد به ازت آمونیومی تبدیل شود.
              </p>
              <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base">
                بیشتر{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="px-6 inline-block w-full lg:w-1/4 mx-1 mb-4 lg:mb-0">
          <div className="s1 border p-5 text-white rounded-3xl border-[#143591] text-right">
            <div className="date inline-block w-1/3 lg:w-1/2">
              <p className="text-base md:text-xs text-[#c0bfba]">
                ۲۸ فروردین، ۱۴۰۳
              </p>
            </div>
            <div className="category inline-block w-1/2">
              <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-1 px-1 rounded-2xl text-center">
                کودها
              </p>
            </div>
            <div className="blog">
              <p className="text-white w-full my-4 text-2xl md:text-xl rtl">
                تحریک ریشه زایی و درشت شدن محصول.
              </p>
              <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4 rtl">
                فرمول فوق به علت اینکه هر ۴ فرم ازت را دارا میباشد در بازه زمانی
                ۱٫۵ ماهه که ازت اورهای نیاز دارد به ازت آمونیومی تبدیل شود.
              </p>
              <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base">
                بیشتر{" "}
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Blog;
