import Footer from "../../Newfooter/newfooter";
import Header from "../../header/Navbar";

const NotFound = () => {
  return (
    <>
      <div className="text-white bgfound">
        <Header />
        <h1 className="text-center text-7xl font-bold mt-[90px]">404</h1>
        <h2 className="text-center text-4xl font-semibold mb-[90px] mt-3">
          Page Not Found
        </h2>
        <Footer />
      </div>
    </>
  );
};
export default NotFound;
