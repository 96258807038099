import Header from "../../header/Navbar";
import Footer from "../../Newfooter/newfooter";
import Blogdata from "./Blogdata";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useParams } from "react-router-dom";
const Blogdetail = () => {
  const { blogId } = useParams();
  const thisBlog = Blogdata.find((blog) => blog.id === blogId);
  return (
    <>
      <div className="bgpost EnglishFont">
        <Header />
        <div className="w-1/2 mx-auto">
          <div className="block pb-10 border-b border-[#807E75]">
            <Link to="/blog">
              <KeyboardBackspaceIcon className="text-[#2159f2] inline-block mr-2" />
            </Link>
            <p className="text-[#c0bfba] text-xl font-normal text-left mt-4 mb-10 inline-block">
              {thisBlog.date}
            </p>
            <p className="text-white text-4xl font-medium mb-7">
              {thisBlog.title}
            </p>
            <p className="text-[#C0BFBA] text-xl">{thisBlog.shortdesc}</p>
          </div>
          <div className="block">
            <p className="text-[#C0BFBA] text-lg mt-7 min-h-40">
              {thisBlog.desc}
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Blogdetail;
