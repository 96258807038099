import { Link } from "react-router-dom";
import Footer from "../../Newfooter/newfooter";
// import Header from "../../header/header";
import Header from "../../header/Navbar";
import InActionData from "./InActionData";

const InAction = () => {
  return (
    <>
      <div className="bgaction text-white EnglishFont">
        <Header />
        <div className="container mx-auto my-10 md:my-20 lg:my-32 text-center">
          <h1 className="text-xl text-white font-bold mb-10 md:text-3xl lg:text-4xl">
            Projects In Action
          </h1>
          <p className="text-lg text-white font-medium w-full lg:w-1/2 mx-auto py-3">
            We seek to provide quality products with international standards in
            all value-creation chains.
          </p>
        </div>
        <div>
          {InActionData?.map((elem) => {
            return (
              <div className="px-5 container mx-auto text-center" key={elem.id}>
                <div className="border rounded-2xl border-[#143591] p-5 w-full 2xl:w-10/12 mx-auto my-5">
                  <div className="text-left inline-block md:w-1/2 w-full">
                    <h3 className="text-white md:text-4xl text-lg font-medium mb-3">
                      {elem.title}
                    </h3>
                    <p className="text-gray-500 text-base h-10 md:h-40">
                      {elem.date}
                    </p>
                    <Link
                      className="no-underline text-center"
                      to={`/InAction/${elem.id}`}
                    >
                      <h3 className="no-underline text-white w-40 mb-4 md:mb-0 border rounded-full py-2 px-5 border-[#2159F2] hover:bg-[#2159F2]">
                        Go to project
                      </h3>
                    </Link>
                  </div>
                  <div className="pic w-full md:w-1/2 inline-block">
                    <img src={elem.image}></img>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default InAction;
