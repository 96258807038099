import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import Logo from "../../../../Images/kdk.png";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav id="header" className="App-header sticky top-0 z-50 bg-black">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row items-center justify-between h-16">
          <div className="md:basis-1/6 basis-2/3 items-center inline-block">
            <div className="flex-shrink-0">
              <Link to="/Fa">
                <img
                  className="pb-0 pt-2 p-6 md:p-6 md:pt-6"
                  src={Logo}
                  alt="Logo"
                />
              </Link>
            </div>
          </div>
          <div className="basis-5/6 lg:inline-block hidden">
            <ul className="float-right top-0 md:text-lg text-sm gap-2">
              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/about">درباره ما</Link>
              </li>
              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/inaction">پروژه های در حال توسعه</Link>
              </li>
              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/products">محصولات</Link>
              </li>

              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/job">همکاری با ما</Link>
              </li>
              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/Contact">تماس با ما</Link>
              </li>
              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/auction">مزایده</Link>
              </li>
              {/* <li className="inline-block text-white no-underline list-none p-3">
                آزمایشگاه
              </li> */}
              <li className="inline-block text-white no-underline list-none p-3">
                <Link to="/Fa/blog">اخبار</Link>
              </li>

              <li className="inline-block text-white no-underline list-none">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#FFFFFF"
                  >
                    <path d="m476-80 182-480h84L924-80h-84l-43-122H603L560-80h-84ZM160-200l-56-56 202-202q-35-35-63.5-80T190-640h84q20 39 40 68t48 58q33-33 68.5-92.5T484-720H40v-80h280v-80h80v80h280v80H564q-21 72-63 148t-83 116l96 98-30 82-122-125-202 201Zm468-72h144l-72-204-72 204Z" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
          {/* menu */}
          <div className="-mr-2 flex lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isMenuOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="lg:hidden w-full absolute bg-black" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <ul className="float-right w-full text-right top-0 md:text-lg text-sm gap-2">
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/Fa/about">درباره ما</Link>
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/Fa/inaction">پروژه های در حال توسعه</Link>
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/Fa/products">محصولات</Link>
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/Fa/job">همکاری با ما</Link>
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/Fa/Contact">تماس با ما</Link>
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/Fa/auction">مزایده</Link>
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  آزمایشگاه
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  اخبار
                </li>
                <li className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">
                  <Link to="/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#FFFFFF"
                    >
                      <path d="m476-80 182-480h84L924-80h-84l-43-122H603L560-80h-84ZM160-200l-56-56 202-202q-35-35-63.5-80T190-640h84q20 39 40 68t48 58q33-33 68.5-92.5T484-720H40v-80h280v-80h80v80h280v80H564q-21 72-63 148t-83 116l96 98-30 82-122-125-202 201Zm468-72h144l-72-204-72 204Z" />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export default NavBar;
