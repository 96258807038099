// import { Link } from "react-router-dom";
import Footer from "../../Newfooter/newfooter";
import Header from "../../header/Navbar";
// import AuctionData from "./AuctionData";

const Auction = () => {
  return (
    <>
      <div className="bgaction text-white EnglishFont">
        <Header />
        <div className="container mx-auto my-32 text-center">
          <h1 className="text-xl text-white font-bold mb-10 md:text-5xl">
            KDK Auction
          </h1>
          <p className="text-lg text-white font-medium w-full lg:w-1/2 mx-auto py-3">
            Be aware of auction held by KDK's company.{" "}
          </p>
        </div>
        {/* <div>
          {AuctionData?.map((elem) => {
            return (
              <div className="px-5 container mx-auto text-center" key={elem.id}>
                <div className="border rounded-2xl border-[#84cc16] p-5 w-full 2xl:w-10/12 mx-auto my-5">
                  <div className="pic w-full md:w-1/3 inline-block">
                    <img className="w-4/5 mx-auto" src={elem.image}></img>
                  </div>
                  <div className="text-left inline-block md:w-2/3 w-full">
                    <h3 className="no-underline w-40 mb-4 md:mb-0 rounded-full py-2 px-5 text-[#84cc16] border-0 bg-lime-900 hover:bg-[#84cc16] hover:text-white">
                      Available now
                    </h3>
                    <h3 className="text-white md:text-4xl text-lg font-medium py-5">
                      {elem.title}
                    </h3>
                    <p className="text-white py-5">{elem.desc}</p>
                    <p className="text-gray-500 text-base">{elem.date}</p>
                    <Link
                      className="no-underline"
                      to={`/Auction/${elem.id}`}
                    > 
                    <h3 className="no-underline text-white w-40 mb-4 md:mb-0 border rounded-full py-2 px-5 border-[#84cc16] hover:bg-[#84cc16] text-center float-right">
                      Place a bid
                    </h3>
                   </Link> 
                  </div>
                </div>
              </div>
            );
          })}
        </div>*/}
        <Footer />
      </div>
    </>
  );
};

export default Auction;
