import { Link } from "react-router-dom";

const Slogun = () => {
  return (
    <>
      <iframe
        src="https://my.spline.design/kdkballswitheffects-8fbf0f873c3ef267d59df2fa4847737a"
        className="w-full h-[400px] border-0 bg-cover absolute  md:min-h-[440px]"
        allowFullScreen
      ></iframe>
      <div className="p-5 text-white space-x-5 mt-14 md:mt-28 container mx-auto w-full lg:w-3/5 relative md:mx-h-[750px]">
        <h1 className="text-white text-3xl mb-10 font-bold lg:text-5xl">
          New world, New Solution
        </h1>
        <p className="text-white md:text-2xl text-base mb-10 font-normal ">
          تولید کننده تاثیر‌گذار محصولات شیمایی بر‌پایه فسفر
        </p>
        <Link to="/Fa/about">
          <button className="border text-sm py-3 px-4 w-1/3 rounded-3xl border-[#2159F2] hover:bg-[#2159F2] lg:rounded-full lg:px-8 lg:w-1/3 md:w-1/4">
            مطالعه بیشتر
          </button>
        </Link>
        <button className="border text-sm rounded-3xl py-3 px-4 w-2/4 border-[#2159F2] hover:bg-[#2159F2] lg:rounded-full lg:px-8 lg:w-1/3 md:w-1/4">
          ثبت سفارش
        </button>
      </div>
    </>
  );
};
export default Slogun;
