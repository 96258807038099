let item = [
  {
    id: "1",
    title: "Human Resources Manager",
    titlefa: "مدیر منابع انسانی",
    position: "KDK Headquarters",
    location: "Tehran,Iran",
    locationfa: "ایران،تهران",
    condition: [
      "Working days and hours: Saturday to Wednesday.",
      "Business trips: 3 days business trip to Yazd province.",
      "Facilities and Benefits: Bonuses and commissions, Loan, Health insurance.",
    ],
    conditionfa: [
      "روز و ساعت کاری: شنبه تا چهارشنبه.",
      "سفرهای کاری: 3 روز سفر کاری به استان یزد.",
      "مزایا و تسهیلات: پاداش و پورسانت، بیمه درمان تکمیلی، بسته و هدایای مناسبتی",
    ],
    indicator: [
      "10 years of work experience in the same job group.",
      "Work experience in the manufacturing industry.",
      "Management experience.",
    ],
    indicatorfa: [
      "10 سال سابقه کار در گروه شغلی مشابه.",
      "سابقه کار در صنعت تولیدی/صنعتی.",
      "سابقه کار مدیریتی.",
    ],
    desc: [
      "Develop detailed job descriptions and interview questions, source candidates, and" +
        "coordinate with hiring managers to meet staffing needs. Implement assessments and" +
        "manage the entire hiring process, including onboarding new hires.",
      "Form and oversee recruitment and interview committees, ensuring they are well-prepared" +
        "and aligned with hiring goals.",
      "Manage the screening and onboarding of recruits, ensuring a smooth transition from trial" +
        "to permanent employment.",
      "Conduct exit interviews to gather feedback for organizational improvement and plan for" +
        "replacing key personnel efficiently.",
      "Maintain and update organizational charts and job descriptions to reflect current roles and" +
        "responsibilities accurately.",
      "Ensure compliance with labor laws and regulations.",
      "Design and propose wage structures and strategies for continuous organizational" +
        "improvement.",
      "Develop and implement talent management programs and succession plans to identify and" +
        "nurture future leaders within the organization.",
      "Create and manage comprehensive training programs for individuals and teams, promoting" +
        "continuous learning and development.",
      "Implement activities and initiatives to enhance organizational culture and employee" +
        "engagement, fostering a positive work environment.",
      "Evaluate and monitor employee performance using management tools.",
      "Set performance goals and provide constructive feedback for improvement.",
      "Implement performance recognition programs to reward excellence. Manage disciplinary" +
        "procedures fairly and consistently to maintain workplace standards.",
      "Develop and manage employee benefits and compensation plans, ensuring they are" +
        "competitive and aligned with industry standards.",
      "Ensure compliance with disciplinary regulations and address employee misconduct" +
        "promptly and effectively.",
      "Develop retention strategies to keep valuable employees committed and satisfied.",
      "Conduct employee satisfaction surveys to gather insights and improve workplace" +
        "conditions.",
      "Mediate and resolve conflicts among individuals and teams, promoting a harmonious work" +
        "environment through effective conflict-resolution strategies.",
      "Monitor employee attendance and manage leave processes to ensure consistency and" +
        "compliance with company policies.",
      "Conducted workforce planning and forecasting to anticipate and meet future staffing" +
        "needs, ensuring the organization was adequately resourced.",
      "Identify employees with decreased productivity and implement performance improvement" +
        "plans to enhance their efficiency and effectiveness.",
      "Handle employee grievances and maintain open communication channels to address" +
        "concerns and promote positive employee relations.",
      "Develop and implement initiatives to promote diversity and inclusion, ensuring a respectful" +
        "and inclusive workplace for all employees.",
      "Ensure compliance with health, safety, and labor laws, managing HR-related risks to" +
        "protect the organization and its employees.",
    ],
    descfa: [
      "نظارت و مدیریت فرایندهای استخدام کارکنان(درج آگهی استخدامی،بررسی رزومه ها،مصاحبه و ...(",
      "تدوین و بازنگری در شرح وظایف و تعریف مشاغل.",
      "اجرای و بازنگری برنامه جبران خسارت و غرامت شرکت.",
      "تدوین،اصلاح و اعمال رویه ها و سیاست های مربوط به پرسنل.",
      "نظارت بر روند اجرای مصاحبه خروج.",
      "ثبت،نگهداری و بایگانی سوابق و گزارش های بخش منابع انسانی.",
      "حضور در جلسات مدیران اجرایی شرکت.",
      "تهیه چارت های سازمانی",
      "تدوین و اجرای استراتژی ها و بینش سازمانی هم سو و هم راستا با استراتژی کلی شرکت",
      "یجاد پل ارتباطی بین شرکت و کارمندان از طریق رسیدگی به خواسته ها،شکایات یا سایر موارد",
      "تدوین و مدیریت فرایندهای جذب و گزینش کارکنان",
      "پشتیبانی و حمایت از نیازهای فعلی و آینده سازمان از طریق توسعه،تعامل،ایجاد انگیزه و حفظ سرمایه انسانی",
      "تدوین،توسعه و نظارت بر تمام استراتژی ها،سیستم ها،تاکتیک ها و رویه های کلی سازمان",
      "کمک به ایجاد محیط کاری مثبت و سازنده",
      "نظارت و مدیریت سیستم ارزیابی عملکرد کارکنان برای بهبود و افزایش بازدهی",
      "ارزیابی نیازهای آموزشی و برگزاری و نظارت بر برنامه‌ها و دوره‌های آموزشی",
      "ارائه گزارش‌های منظم به مدیریت کل و اطمینان از هم‌سو بودن تصمیمات کلان با معیارهای کارمندگرا",
      "اطمینان از انطباق کارکنان با قوانین و رویه‌های سازمان",
      "تجزیه و تحلیل داده‌ها و گزارش‌های سالانه حقوق و دستمزد برای تدوین برنامه‌های جبران خدمات",
      "اطمینان از انطباق قوانین و سیاست‌های شرکت با قوانین حقوقی کشور از طریق مشورت با وکیل یا مشاور حقوقی شرکت",
      "ثبت و بایگانی اسناد و مدارک مربوط به مزایای کارکنان مثل طرح بیمه و بازنشستگی",
      "نظارت بر بایگانی اسناد مربوط به جلسات مذاکره با کارکنان از جمله صورت جلسه مصاحبه استخدامی، ارتقاء شغلی، انتقال و جابجایی، بررسی عملکرد و خاتمه همکاری",
      "تدوین برنامه‌های جانبی و مزایای شغلی برای کارکنان مثل طرح‌های بیمه عمر، بیمه سلامت و دندان پزشکی، بازنشستگی، بیمه تکمیلی و مرخصی استعلاجی",
      "تحصیلات در رشته مدیریت منابع انسانی مزیت محسوب میشود",
    ],
  },
  // {
  //   id: "2",
  //   title: "Human Resources Manager",
  //   position: "KDK Headquarters",
  //   location: "Tehran,Iran",
  //   condition: [
  //     "Working days and hours: Saturday to Wednesday.",
  //     "Business trips: 3 days business trip to Yazd province.",
  //     "Benefits and facilities: bonus, compensation, supplementary treatment insurance, package, and special gifts.",
  //   ],
  // },
];

export default item;
