// import Product from "./Product/product";
import productsData from "../../Products/ProductsData";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "../../../../../styles.css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";

const Productlist = () => {
  return (
    <>
      <div className="p-5 container w-full mx-auto">
        <div className="product text-center container mx-auto w-full">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={true}
            modules={Navigation}
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              700: {
                slidesPerView: 3,
              },
              870: {
                slidesPerView: 4,
              },
            }}
            className="myproduct"
          >
            {productsData?.map((product) => {
              return (
                <SwiperSlide key={product.id}>
                  <div className="mb-4 lg:mb-0 inline-block">
                    <Link to={`/Fa/products/${product.id}`}>
                      <div className="p1 w-11/12">
                        <img
                          className="w-full rounded-t-3xl"
                          src={product.coverimage}
                        ></img>
                      </div>
                      <div className="w-11/12 border-[#143591] border rounded-b-lg p-3 pt-0">
                        <h3 className="text-white text-right">
                          {product.titleb}
                        </h3>
                        <p className="text-right text-xs md:text-base text-[#c0bfba]">
                          {product.title}
                        </p>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* {ProductsData?.map((product) => {
            return (
              <div
                className="w-1/2 mb-4 lg:w-1/4 lg:mb-0 inline-block"
                category_id={product.categoryId}
                key={product.id}
              >
                <div className="p1 w-11/12">
                  <img
                    className="w-full rounded-t-3xl"
                    src={product.image}
                  ></img>
                </div>

                <div className="w-11/12 border-[#143591] border rounded-b-lg p-3 pt-0">
                  <Link to={`/Fa/products/${product.id}`}>
                    <p className="text-xs md:text-lg text-right text-white">
                      {product.titleb}
                    </p>
                  </Link>
                  <p className="text-right text-[10px] md:text-base text-[#C0BFBA]">
                    {product.title}
                  </p>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    </>
  );
};
export default Productlist;
