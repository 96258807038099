// import Header from "../../header/header";
import Header from "../../header/Navbar";
// import Productlist from "../../Products/productlist";
import Footer from "../../Newfooter/newfooter";
import Filter from "../../Products/Product/filter";

const Products = () => {
  return (
    <>
      <div className="bg-black text-center EnglishFont">
        <Header />
        <div className="bgproduct py-10">
          <p className="text-white text-center text-3xl md:text-5xl font-semibold mb-10">
            Explore Products
          </p>
          <p className="text-white mx-auto md:text-xl w-7/12 py-5">
            To view comprehensive details of the products and complete the order
            registration process, please click on the required product name.
          </p>
          <Filter />

          {/* <Productlist /> */}
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Products;
