import { React, useState } from "react";
import { useParams } from "react-router-dom";
import productsData from "./productsData";
import Header from "../header/Navbar";
import Footer from "../Newfooter/newfooter";
import CheckIcon from "@mui/icons-material/Check";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../styles.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CircleIcon from "@mui/icons-material/Circle";

const ProductDetail = () => {
  const { productId } = useParams();
  const thisProduct = productsData.find(
    (prod) => prod.categoryId === productId
  );
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <div className="bgdetail EnglishFont">
        <Header />
        <div className="container mx-auto w-full text-center mt-12 md:mt-20">
          <div className="text-left float-left p-5 w-full md:w-1/2 ml-1 md:ml-14">
            <div className="inline-block">
              <p className="text-[#D9D9D9] inline-block text-sm font-light mr-2">
                Products
              </p>
              <CircleIcon color="primary" sx={{ fontSize: 8 }} />
              <p className="text-[#D9D9D9] inline-block text-sm font-light ml-2">
                {thisProduct.title}
              </p>
            </div>
            <div className="w-full block mt-5">
              <p className="text-white text-3xl">{thisProduct.titleb}</p>
              <p className="text-green-400 bg-green-800 text-center w-fit rounded-3xl my-6 py-1 px-3 text-base">
                {thisProduct.title}
              </p>
            </div>
            <div className="w-full block">
              <p className="text-neutral-200 text-base">{thisProduct.desc}</p>
            </div>
            <div className="w-full block mt-5">
              {productsData?.map((product) => {
                <p className="text-white text-sm ">{productsData.bullet}</p>;
              })}
            </div>
            <div className="inline-block w-full mt-5">
              <button className="border border-green-400 rounded-3xl text-white w-2/5 md:w-1/3 text-sm md:text-base my-6 py-2 px-4">
                place your order
              </button>
              <button className="text-white w-1/2 md:w-3/5 text-sm md:text-base ml-6">
                See other ways to purchase
              </button>
            </div>
          </div>
          <div className="w-full md:w-2/5 p-5 md:p-0 inline-block">
            {/* <img className="w-10/12 rounded-2xl" src={thisProduct.image}></img> */}
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {thisProduct.image.map((image, index) => (
                <SwiperSlide key={index}>
                  <img className="w-10/12 rounded-2xl" src={image} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper mythumSwiper"
            >
              {thisProduct.image.map((image, index) => (
                <SwiperSlide key={index}>
                  <img className="w-1/2 rounded-2xl" src={image} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="border-t border-blue-600 mt-10 mb-10 inline-block">
            <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 mx-1 mt-10 text-left min-h-40">
              <div className="s1 text-white rounded-3xl mx-1 text-left table w-full">
                <div className="table-row-group">
                  <div className="table-cell px-6">
                    <CheckIcon className="text-[#00D777]" />
                  </div>
                  <div className="blog table-cell">
                    <p className="text-white w-full my-4 text-2xl md:text-xl">
                      Premium Quality
                    </p>
                    <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                      Our acids are formulated with the highest standards,
                      ensuring superior quality for your chemical requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 mx-1 mt-10 text-left min-h-40">
              <div className="s1 text-white rounded-3xl mx-1 text-left table w-full">
                <div className="table-row-group">
                  <div className="table-cell px-6">
                    <CheckIcon className="text-[#00D777]" />
                  </div>
                  <div className="blog table-cell">
                    <p className="text-white w-full my-4 text-2xl md:text-xl">
                      Versatile Applications
                    </p>
                    <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                      Our acids find applications in diverse industries,
                      offering unmatched flexibility for manufacturing,
                      laboratories, and research purposes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="s1 inline-block text-white rounded-3xl w-100 md:w-1/4 mx-1 mt-10 text-left min-h-40">
              <div className="s1 text-white rounded-3xl mx-1 text-left table w-full">
                <div className="table-row-group">
                  <div className="table-cell px-6">
                    <CheckIcon className="text-[#00D777]" />
                  </div>
                  <div className="blog table-cell">
                    <p className="text-white w-full my-4 text-2xl md:text-xl">
                      Reliable Performance
                    </p>
                    <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                      Trust our acids to consistently deliver reliable results,
                      empowering you with precision and confidence in your
                      endeavors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProductDetail;
