import React from "react";
// import Header from "../../header/header";
import Header from "../../header/Navbar";
import Footer from "../../Newfooter/newfooter";
import Contact from "../../Contact/contact";

export default function App() {
  return (
    <>
      <div className="bg-black EnglishFont">
        <Header />
        <Contact />
        <Footer />
      </div>
    </>
  );
}
