import { useState } from "react";

const Contact = () => {
  const [data, setData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Message: "",
  });
  const setFirstName = (e) => {
    console.log(data);
    setData({ ...data, FirstName: e.target.value });
  };
  const setLastName = (e) => {
    console.log(data);
    setData({ ...data, LastName: e.target.value });
  };
  const setEmail = (e) => {
    console.log(data);
    setData({ ...data, Email: e.target.value });
  };
  const setPhone = (e) => {
    console.log(data);
    setData({ ...data, Phone: e.target.value });
  };
  const setMessage = (e) => {
    console.log(data);
    setData({ ...data, Message: e.target.value });
  };
  return (
    <div className="bg-black">
      <section className="text-gray-700 body-font relative">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white text-center">
              با ما در ارتباط باشید
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-white text-center rtl">
              اگر سوالی دارید ، لطفاً از طریق فرم زیر با ما تماس بگیرید و یکی از
              اعضای تیم مستقیماً با شما تماس خواهد گرفت.{" "}
            </p>
          </div>
          <div className="lg:w-1/2 md:w-2/3 mx-auto text-right">
            <div className="flex flex-wrap -m-2">
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="name"
                    className="leading-7 text-sm text-white text-right"
                  >
                    نام
                  </label>
                  <input
                    onChange={(e) => setFirstName(e)}
                    type="text"
                    id="FirstName"
                    name="name"
                    className="w-full placeholder-gray-500 text-white placeholder-opacity-100 bg-transparent rounded border border-regal-blue focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="name"
                    className="leading-7 text-sm text-white text-right"
                  >
                    نام خانوادگی
                  </label>
                  <input
                    onChange={(e) => setLastName(e)}
                    type="text"
                    id="LastName"
                    name="name"
                    className="w-full placeholder-gray-500 text-white placeholder-opacity-100 bg-transparent rounded border border-regal-blue focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="email"
                    className="leading-7 text-sm text-white text-right"
                  >
                    آدرس ایمیل
                  </label>
                  <input
                    onChange={(e) => setEmail(e)}
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-transparent text-white rounded border border-regal-blue focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="phone"
                    className="leading-7 text-sm text-white text-right"
                  >
                    شماره تلفن
                  </label>
                  <input
                    onChange={(e) => setPhone(e)}
                    type="text"
                    id="phone"
                    name="phone"
                    className="w-full bg-transparent text-white rounded border border-regal-blue focus:border-indigo-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-full">
                <div className="relative">
                  <label
                    for="message"
                    className="leading-7 text-sm text-white text-right"
                  >
                    پیام شما
                  </label>
                  <textarea
                    onChange={(e) => setMessage(e)}
                    id="message"
                    name="message"
                    placeholder="...پیام خود را در اینجا بنویسید"
                    className=" text-right w-full bg-transparent text-white rounded border border-regal-blue focus:border-indigo-500 h-36 text-base outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
              </div>
              <div className="p-2 w-full">
                <button className="w-full mx-auto text-white text-center bg-[#2159F2] border-0 py-2 px-8 rounded-full focus:outline-none text-lg">
                  ارسال
                </button>
                {/* <div className="block text-white text-lg container mx-auto mt-5">
                  <h5>FirstName:{data.FirstName}</h5>
                  <h5>LastName:{data.LastName}</h5>
                  <h5>Email:{data.Email}</h5>
                  <h5>Phone:{data.Phone}</h5>
                  <h5>Message:{data.Message}</h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
