import blogData from "./blogData";

const Blog = () => {
  return (
    <>
      <div className="section5 mt-10 container mx-auto w-full inline-grid">
        <p className="text-base text-[#00D777]">BLOGS & NEWS</p>
        <h2 className="md:text-3xl text-white text-xl mb-1">
          Read about Our Stories and Latest News
        </h2>
        <p className="text-white text-base mb-10">
          Read the Latest News from KDK
        </p>
        <div className="justify-self-center">
          {blogData?.map((blog) => {
            return (
              <div className="px-6 inline-block w-full lg:w-1/4 mx-1 mb-4 lg:mb-0 align-bottom">
                <div className="s1 grid border p-5 text-white rounded-3xl border-[#143591] text-left  min-h-[360px]">
                  <div className="inline-block w-full">
                    <p className="text-base md:text-xs text-[#c0bfba] w-1/3 lg:w-1/2 inline-block">
                      {blog.date}
                    </p>
                    <p className="text-[#4d7af5] bg-[#071230] text-base md:text-xs py-1 px-1 rounded-2xl text-center w-1/2 inline-block">
                      {blog.group}
                    </p>
                  </div>
                  <p className="text-white w-full my-4 text-2xl md:text-xl">
                    {blog.title}
                  </p>
                  <p className="text-[#c0bfba] font-normal text-base md:text-sm mb-4">
                    {blog.desc}
                  </p>
                  <button className="pb-2 pt-1 px-7 border border-[#2159f2] rounded-3xl text-base w-fit">
                    Read More
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Blog;
