let item = [
  {
    id: "1",
    date: "22 April",
    group: "News",
    title:
      "The localization of 'Triple Super Phosphate' fertilizer has resulted in 37 million dollars in annual savings",
    desc: "Localization of 'Triple Super Phosphate' (TSP) fertilizer with an annual capacity of 100,000 tons, also known as 'black fertilizer,' is one of the richest types of phosphate chemical fertilizers.",
  },
  {
    id: "2",
    date: "3 April",
    group: "Chemicals & Acids",
    title: "What is sulfuric acid?",
    desc: "Sulfuric acid is a potent acid that holds great significance in chemistry. Phosphoric acid production is its main use, which serves as a precursor for phosphate fertilizers like calcium dihydrogen sulfate and ammonium phosphate.",
  },
  {
    id: "3",
    date: "1 May",
    group: "News",
    title:
      "KimiaDaran Kavir's company has obtained a research and development license.",
    desc: "KDK Company succeeded in obtaining a research and development license from the Ministry of Industry, Mines, and Trade.",
  },
];

export default item;
