const AuctionBanner = () => {
  return (
    <>
      <div className="container mx-auto">
        <img
          src={require("../../Images/forsite.png")}
          className="w-full lg:w-3/4 mx-auto my-20"
        ></img>
      </div>
    </>
  );
};
export default AuctionBanner;
