import React from "react";
// import Header from "../Header/Header";
import Header from "../Header/Navbar";
import Footer from "../Footer/Footer";
import ContactFa from "./ContactFa";

export default function App() {
  return (
    <>
      <div className="bg-black PersianFont">
        <Header />
        <ContactFa />
        <Footer />
      </div>
    </>
  );
}
